import React from "react";
import './styles/new_user_information_wrapper.css';
import DropzoneAvatarContainer from "./DropzoneAvatarContainer";
import {useSelector} from "react-redux";
import avatar_default from "../assets/img/new_landing/profile_avatar_default.svg";
import MainButton from "./CustomComponents/MainButton";
import Keys from "../Constants/helper";
import {Link} from "react-router-dom";

export default function NewUserInformationWrapper(props) {
    const {
        // facebook_link, instagram_link, linkedin_link, behance_link, rate, video, subscription, verified, photo, role,
        // eslint-disable-next-line
        categories, country, name,
        backgroundArt, profileInfo, setProfileInfo,
        // eslint-disable-next-line
        surname, publicArtist, user, photo, follow,
        followArtist, premium
    } = props;
    let store = useSelector(store => store.auth.user_data)

    return (
        <div className={`user_information_wrapper ${backgroundArt ? 'user_information_wrapper_art' : ''}`}>
            <div className={'info'}>
                <div className={'avatar_container'}>
                    {publicArtist ?
                        <div className={'change_avatar_wrapper'}>
                            <img src={photo ?? avatar_default} alt={`${name} - Artist`}/>
                        </div> :
                        <DropzoneAvatarContainer data={profileInfo}
                                                 setData={setProfileInfo}
                                                 profilePhoto/>}
                </div>
                <div className={'about'}>
                    <div className={'portfolio-name-container'}>
                        {publicArtist ?
                            <h1>{"The artist:"} <br/> {name}</h1> :
                            <h1>{store.role !== 'investor' ? "The artist:" : ""} {store.role !== 'investor' ?
                                <br/> : ""}{store?.name ?? name} {store?.surname ?? surname ?? ''}</h1>
                        }
                        {(user ? user.premium : premium) ?
                            <svg className={'portfolio-name-container-svg'} width="16" height="16" viewBox="0 0 16 16"
                                 fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M7.99987 15.4668C7.01933 15.4668 6.0484 15.2736 5.1425 14.8984C4.2366 14.5232 3.41348 13.9732 2.72014 13.2798C2.02679 12.5865 1.4768 11.7634 1.10157 10.8575C0.726334 9.95159 0.533203 8.98065 0.533203 8.00011C0.533203 7.01958 0.726334 6.04864 1.10157 5.14274C1.4768 4.23685 2.02679 3.41373 2.72014 2.72038C3.41348 2.02704 4.2366 1.47705 5.1425 1.10181C6.0484 0.726578 7.01933 0.533447 7.99987 0.533447C9.98015 0.533447 11.8793 1.32011 13.2796 2.72038C14.6799 4.12065 15.4665 6.01983 15.4665 8.00011C15.4665 9.9804 14.6799 11.8796 13.2796 13.2798C11.8793 14.6801 9.98015 15.4668 7.99987 15.4668Z"
                                    fill="#3473FF"/>
                                <path d="M4.26758 8.00016L7.46758 10.6668L11.7342 5.3335" stroke="white"
                                      strokeWidth="1.6" strokeLinecap="round"/>
                            </svg>
                            : null}
                    </div>
                    <h2>{publicArtist ? user.country : store.country ?? country}</h2>
                    <div className={'categories'}>
                        {categories?.length ? categories.map((el, i) => (
                            <Link key={i} to={`/artworks/${el}`}>
                                <span># {el}</span>
                            </Link>
                        )) : null}
                    </div>
                    {localStorage.getItem(Keys.JWT_TOKEN) && publicArtist && store.role === 'investor' ?
                        <div className={'follow_artist'}>
                            <MainButton text={follow ? "Unfollow" : "Follow"}
                                        onClick={followArtist}
                                        className={'button_black'}/>
                        </div>
                        : null}
                </div>
            </div>
        </div>
    )
}
