import React, {useState, useEffect} from 'react'
import API from '../../api/API';
import categoryClose from "../../assets/img/personal_registration/category_close.svg";
import TextInput from "../CustomComponents/TextInput";
import regExp from "../../Constants/regExp";
import RangeSliderMultiple from "../CustomComponents/RangeSliderMultiple";
import vector from "../../assets/img/chevron-down.svg";
import OutsideClickHandler from "../../Constants/detectOutsideClick";
import {useGetLastChartHash} from "../../Constants/useGetLastChartHash";
import {getActivePage} from "../../Constants/getActivePage";
//import {useLocation} from "react-router-dom";
import {useHistory} from "react-router";

export default function FilterWrapper(initialState, getFilterRequest, listType, filterListRequest, token = null, isAdmin) {
    let {activeHashUrl} = useGetLastChartHash()

    // console.log(initialState);

    const [filtersObject, setFiltersObject] = useState(initialState)
    const [activeFilter, setActiveFilter] = useState(null)
    const [activeFilterArray, setActiveFilterArray] = useState([])
    const [showMoreFilters, setShowMoreFilters] = useState(null);
    const [loading, setLoading] = useState(null);
    const [listingData, setListingData] = useState(() => ({page: null}));
    const [showMobileFilters, setShowMobileFilters] = useState(null);

    const history = useHistory()

    const deleteActiveFilterArray = item => {
        let newFilter = filtersObject[item.category].activeList?.filter(i => i.name !== item.name);
        let filter = activeFilterArray.filter(i => i.name !== item.name)
        setActiveFilterArray(filter)
        setFiltersObject(prevState => ({
            ...prevState,
            [item.category]: {
                ...prevState[item.category],
                activeList: newFilter
            }
        }))
        let categoryName = new URLSearchParams(window.location.search).get("category")
        if ((!filter.length || filter.some(e => e.name !== categoryName)) && !isAdmin) {
            // window.history.replaceState({}, '', '/artworks');
        }
    }


    const handleDetectOutSide = () => setActiveFilter(null)

    const revertObject = obj => {
        if (filtersObject[obj]) {
            return filtersObject[obj]?.activeList?.map(i => i.name);
        } else {
            return []
        }
    }

    const getCategory = (categoryArray) => {
        const nftPage = "/admin/nfts";
        const artworkPage = '/admin/artworks';
        const isNftPage = history.location.pathname?.includes(nftPage);
        const isArtworkPage = artworkPage === history.location.pathname.trim();
        if (isNftPage){
            console.log('Yes sir')
            return categoryArray?.length ? `&categories=NFT` : '';
        } else if (isArtworkPage) {
            return categoryArray?.length ? ` ` : '';
        }
        else {
            return categoryArray?.length ? `&categories=${categoryArray}` : '';
        }
    }

    useEffect(() => {
        if (activeHashUrl) {
            setListingData(prevState => ({
                ...prevState,
                page: activeHashUrl
            }));
        }
    }, [activeHashUrl])

    useEffect(() => {
        if (loading) {
            let activeFilterArray = [];
            Object.entries(filtersObject).forEach(i => {
                if (i[1] instanceof Object) {
                    i[1].activeList?.forEach(elem => {
                        activeFilterArray.push(elem)
                    })
                }
            })
            setActiveFilterArray(activeFilterArray)
        }
    }, [filtersObject, loading])

    const getFilters = (activePage, searchArg = null) => {
        let categoryArray = revertObject('category'),
            countryArray = revertObject('country'),
            stylesArray = revertObject('styles'),
            materialsArray = revertObject('materials'),
            status = `${filtersObject?.status ? `&hide=${filtersObject?.status?.value === 'hidden' ? 1 : 0}` : ''}`,
            categories_request = getCategory(categoryArray),
            country_request = countryArray?.length ? `&countries=${countryArray}` : '',
            search = filtersObject.listing_search.value.length > 1 ? `&name=${searchArg ?? filtersObject.listing_search.value}` : '',
            height = '',
            width = '',
            price = '',
            sort_by_request = '',
            materials_request = '',
            styles_request = ''
        if (listType === 'artworks') {
            sort_by_request = filtersObject.sort_by.activeList[0]?.value ? `&order_by=${filtersObject.sort_by.activeList[0].value}` : ''
            styles_request = stylesArray?.length ? `&styles=${stylesArray}` : ''
            materials_request = materialsArray?.length ? `&materials=${materialsArray}` : ''
            price = `&min_price=${filtersObject.price.min}&max_price=${filtersObject.price.max}`
            height = `&height=${filtersObject.height.min}-${filtersObject.height.max}`
            width = `&width=${filtersObject.width.min}-${filtersObject.width.max}`;
        }
        // alert(categories_request)
        // alert(styles_request)
        let request = `${filterListRequest}?page=${activePage ?? listingData.page}${status}${search}&per_page=15${price}${country_request}${sort_by_request}${height}${width}${categories_request}${styles_request}${materials_request}`
        // alert(request)
        API(token ?? null).get(request)
            .then(res => {
                console.log(res);
                setListingData(prevState => ({
                    ...prevState,
                    ...res.data
                }))
                setLoading(true)
            })
    }

    console.log('listType: ', listType)

    useEffect(() => {
        if (filtersObject.listing_search.value.length >= 2) {
            getActivePage(1)
            getFilters(1)
        } else if (filtersObject.listing_search.value.length) {
            getFilters(1, '')
        }
        // eslint-disable-next-line
    }, [filtersObject.listing_search.value])

    useEffect(() => {
        if (filtersObject.loading) {
            getFilters()
        }
        // eslint-disable-next-line
    }, [listingData.page, filtersObject?.styles?.activeList,
        filtersObject?.loading,
        filtersObject?.category?.activeList, filtersObject?.materials?.activeList,
        filtersObject?.sort_by?.activeList, filtersObject?.height,
        filtersObject?.width, filtersObject?.country,
        filtersObject?.status?.value,
        filtersObject?.price?.max, filtersObject?.price?.min
    ])

    const setInputPriceRange = (value, rangeValue, category) => {
        setListingData(prevState => ({
            ...prevState,
            page: 1
        }))
        getActivePage(1)
        setFiltersObject(prevState => ({
            ...prevState,
            [category]: {
                ...prevState[category],
                [rangeValue]: Number(value),
            }
        }))
    }

    const showFilterPopup = name => {
        if (activeFilter === name) {
            setActiveFilter(null)
        } else {
            setActiveFilter(name)
        }
    }

    const sortByCustomHandle = item => {
        let uniqueFilter = activeFilterArray;
        if (uniqueFilter?.length) {
            if (uniqueFilter.some(e => e.unique)) {
                uniqueFilter.forEach(i => {
                    if (i.unique) {
                        i.name = item.name
                    }
                })
                setActiveFilterArray([...uniqueFilter])
            } else {
                setActiveFilterArray(prevState => [...prevState, {
                    name: item.name,
                    unique: true,
                    category: 'sort_by'
                }])
            }
        } else {
            setActiveFilterArray(prevState => [...prevState, {
                name: item.name,
                unique: true,
                category: 'sort_by'
            }])
        }
        setFiltersObject(prevState => ({
            ...prevState,
            sort_by: {
                ...prevState.sort_by,
                activeList: [{
                    value: item.name,
                    name: item.preview,
                    category: 'sort_by'
                }]
            },
        }))
        handleDetectOutSide()
    }

    const setActiveFilterFunc = (item, filterCategory) => {
        if (filtersObject[filterCategory].activeList?.length) {
            if (filtersObject[filterCategory].activeList?.some(elem => elem.name === item)) {
                console.log("Object found inside the array.");
            } else {
                setFiltersObject(prevState => ({
                    ...prevState,
                    [filterCategory]: {
                        ...prevState[filterCategory],
                        activeList: [...prevState[filterCategory].activeList, {
                            name: item,
                            category: filterCategory
                        }]
                    }
                }))
            }
        } else {
            setFiltersObject(prevState => ({
                ...prevState,
                [filterCategory]: {
                    ...prevState[filterCategory],
                    activeList: [...prevState[filterCategory].activeList, {
                        name: item,
                        category: filterCategory
                    }]
                }
            }))
        }
    }

    const setPriceRange = (event, val) => {
        if (Number(event.value.min) >= 0 && event.value.max <= filtersObject.max_price) {
            setFiltersObject(prevState => ({
                ...prevState,
                [val]: {
                    min: Number(event.value.min),
                    max: Number(event.value.max)
                }
            }))
        }
    }

    useEffect(() => {
        let categoryName = new URLSearchParams(window.location.search).get("category")
        if (categoryName) {
            setFiltersObject(prevState => ({
                ...prevState,
                category: {
                    ...prevState.category,
                    activeList: [...prevState.category.activeList, {
                        name: categoryName,
                        category: 'category'
                    }]
                }
            }))
        }

        if (!getFilterRequest)
            return

        API(token ?? null).get(getFilterRequest)
            .then(res => {
                // console.log(res)
                if (listType === 'artworks') {
                    setFiltersObject(prevState => ({
                        ...prevState,
                        materials: {...prevState.materials, list: res.data.materials},
                        category: {...prevState.category, list: res.data.category},
                        styles: {...prevState.styles, list: res.data.styles},
                        max_price: res.data.max_price,
                        price: {...prevState.price, max: res.data.max_price},
                        loading: true
                    }))

                } else if (listType === 'artists') {
                    setFiltersObject(prevState => ({
                        ...prevState,
                        country: {...prevState.country, list: res.data.country},
                        category: {...prevState.category, list: res.data.category},
                        loading: true
                    }))
                }
            })
    }, [getFilterRequest, listType, token])

    const filterViewPriceRange = mobile => (
        <div className={`every_filter__popup ${mobile ? 'overflow_scrollbar' : ''}`}>

            <div className={'input_range'}>
                <TextInput value={filtersObject.price.min}
                           placeholder={0}
                           title={'Min'}
                           onChange={({target}) => {
                               let {value} = target
                               if (regExp.only_number.test(value) && Number(value) < Number(filtersObject.price.max)) {
                                   setInputPriceRange(Number(value), 'min', 'price')
                               }
                           }}/>
                <span>-</span>
                <TextInput value={filtersObject.price.max}
                           title={'Max'}
                           placeholder={filtersObject.max_price}
                           onChange={({target}) => {
                               let {value} = target
                               if (regExp.only_number.test(value) && Number(value) <= filtersObject.max_price) {
                                   if (value > filtersObject.price.min) {
                                       //todo: set price
                                       setInputPriceRange(Number(value), 'max', 'price')
                                   } else {
                                       setInputPriceRange(Number(value), 'max', 'price')
                                       setInputPriceRange(0, 'min', 'price')
                                   }
                               }
                           }}/>
            </div>
            <RangeSliderMultiple setValue={e => {
                setPriceRange(e, 'price')
                setListingData(prevState => ({
                    ...prevState, page: 1
                }))
                getActivePage(1)
            }}
                                 value={filtersObject.price}
                                 step={200}
                                 max_price={filtersObject.max_price}/>
        </div>
    )

    const filterViewPrice = mobile => (
        <div className={`every_filter price ${activeFilter === 'price' ? 'not_event' : ''}`}>
            <OutsideClickHandler callback={() => activeFilter === 'price' && !mobile && handleDetectOutSide()}>
                <div className={'category_wrapper'} onClick={() => showFilterPopup('price')}>
                    <span>Price</span>
                    <img src={vector}
                         className={`${activeFilter === 'price' ? 'active' : ''}`}
                         alt="vector"/>
                </div>
                {activeFilter === 'price' ?
                    filterViewPriceRange(mobile)
                    : null}

            </OutsideClickHandler>
        </div>
    )

    const categoryViewSection = (theme, customArr) => (
        <div className={`${theme === 'gray' ? 'theme_gray' : ''} categories_options_container wrapper_every_section`}>
            {(customArr || activeFilterArray).map(item => (
                <div>
                    {!!item.name && item.name !== 'nftCategory' &&
                    <div key={item.name}
                         className={'categories_options'}>
                        <span>{item.name}</span>
                        <img onClick={() => {
                            deleteActiveFilterArray(item, activeFilterArray, setActiveFilterArray)
                        }}
                             src={categoryClose} alt="close icon"/>
                    </div>
                    }
                </div>
            ))}
        </div>
    )

    const viewMoreFilters = text => (
        <div className={`mobile_show_filters`}
             style={showMobileFilters ? {pointerEvents: 'none'} : null}
             onClick={() => setShowMobileFilters(!showMobileFilters)}>
            <span>{text}</span>
            {showMobileFilters ?
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M18.25 9.5C18.25 14.3325 14.3325 18.25 9.5 18.25C4.66751 18.25 0.75 14.3325 0.75 9.5C0.75 4.66751 4.66751 0.75 9.5 0.75C14.3325 0.75 18.25 4.66751 18.25 9.5Z"
                        stroke={'#cccbcb'} strokeWidth="1.5"/>
                    <path d="M12.2295 6.7717L6.77273 12.2285" stroke={'#cccbcb'} strokeLinecap="round"/>
                    <path d="M12.2292 12.2283L6.77246 6.77153" stroke={'#cccbcb'}
                          strokeLinecap="round"/>
                </svg>
                : <svg xmlns="http://www.w3.org/2000/svg" width="16.887" height="9.603"
                       viewBox="0 0 16.887 9.603">
                    <g id="noun_Arrow_2289377" transform="translate(-32 -31.787)">
                        <path id="Path_4555"
                              fill={'#cccbcb'}
                              d="M63.374 41.39a1.119 1.119 0 0 1-.794-.325 1.078 1.078 0 0 1 .036-1.516l3.1-2.959-3.1-2.959A1.072 1.072 0 1 1 64.1 32.08l3.753 3.572a1.31 1.31 0 0 1 .4.938 1.251 1.251 0 0 1-.4.938L64.1 41.1a1.073 1.073 0 0 1-.726.29z"
                              className="cls-1" transform="translate(-19.358)"/>
                        <path id="Path_4556"
                              fill={'#cccbcb'}
                              d="M47.444 44.165H33.083a1.083 1.083 0 0 1 0-2.165h14.361a1.063 1.063 0 0 1 1.083 1.083 1.086 1.086 0 0 1-1.083 1.082z"
                              className="cls-1" transform="translate(0 -6.528)"/>
                    </g>
                </svg>
            }
        </div>
    )

    return {
        filtersObject,
        setFiltersObject,
        showFilterPopup,
        deleteActiveFilterArray,
        showMoreFilters,
        activeFilter,
        showMobileFilters,
        setShowMobileFilters,
        setActiveFilter,
        setShowMoreFilters,
        setPriceRange,
        listingData,
        viewMoreFilters,
        setListingData,
        sortByCustomHandle,
        setActiveFilterFunc,
        categoryViewSection,
        activeFilterArray,
        filterViewPrice,
        setActiveFilterArray,
        loading,
        setInputPriceRange,
        handleDetectOutSide
    }
}
