import {Col, Row, Space, Typography, Upload} from "antd";
import clsx from "clsx";
import moment from "moment";
import {useFormik} from "formik";
import {Fragment, useEffect, useReducer, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {usePlacesWidget} from "react-google-autocomplete";
import {useDispatch, useSelector} from "react-redux";

import API from "../../../api/API";
import {deleteListingFailed, deleteListingRequest} from "../../../main-store/listing/actions";

import TextInput from "../../CustomComponents/TextInput";
import StyledCheckbox from "../../ui/StyledCheckbox";
import StyledInput from "../../ui/StyledInput";
import StyledRangeSlider from "../../ui/StyledRangeSlider";
import StyledButton from "../../ui/StyledButton";
import StyledModal from "../../ui/StyledModal";
import StyledDatePicker from "../../ui/StyledDatePicker";
import IconButton from "../../ui/IconButton";
import GoogleMap from "../../GoogleMap";

import {CloseIcon, CloseIconThin, PlusIconOutlined, QuestionMarkOutlinedIcon, UploadIcon} from "../../../assets/icons";

import {ListingFormSchemaValidation, places} from "./ListingForm.constants";
import {getSelectedTimeValue} from "./ListingForm.helpers";
import styles from "./ListingForm.module.css";

export default function ListingForm({id, data}) {
    const history = useHistory();
    const dispatch = useDispatch();
    const mainImage = data?.photos?.find((i) => i.main);
    const [extendedAmenities, setExtendedAmenities] = useState([]);
    const [newAmenities, setNewAmenities] = useState('');
    const [mainPhoto, setMainPhoto] = useState(mainImage);
    const [deletedImages, setDeletedImages] = useState([]);
    const [cancellationPolicy, setCancellationPolicy] = useState([]);
    const [imageUrl, setImageUrl] = useState(mainImage ? `${process.env.REACT_APP_API_URL}/${mainImage.path}` : null);
    const [fileList, setFileList] = useState(data?.photos?.filter((i) => !i.main).map((i) => ({
        uid: i.id,
        url: `${process.env.REACT_APP_API_URL}/${i.path}`,
    })) ?? []);
    const [isDeleteModelOpen, toggleDeleteModalOpen] = useReducer(state => !state, false);
    const {isDeleting} = useSelector(state => state.listings);

    const {errors, values, isSubmitting, setSubmitting, handleChange, handleSubmit, setFieldValue} = useFormik({
        initialValues: data ? {
            ...data,
            cancellation_policy_id: data.cancellation_policy?.id
        } : {
            guests_num_min: 0,
            guests_num_max: 500
        },
        validateOnChange: false,
        enableReinitialize: true,
        validationSchema: ListingFormSchemaValidation,
        onSubmit: (values) => {
            const formData = new FormData();
            fileList.filter((i) => i.originFileObj).forEach((photo) => formData.append('photos', photo.originFileObj))
            if (mainPhoto) {
                formData.append('main_photo', mainPhoto)
            }
            Object.entries(values).forEach(([k, v]) => {
                if (Array.isArray(v)) {
                    formData.append(k, JSON.stringify(v))
                } else {
                    if (k.includes('check_')) {
                        formData.append(k, v.length === 8 ? v.slice(0, -3) : moment(v).format('HH:mm'))
                    } else {
                        formData.append(k, v)
                    }
                }
            })
            if (!id) {
                API().post('/user/listing/stay', formData)
                    .then(() => handleOpenProfileListings())
                    .finally(() => setSubmitting(false))
            } else {
                Promise.all([
                    deletedImages.map((photoId) => API().delete('/listing/photo', {
                        params: {
                            photo_id: photoId,
                            listing_id: id
                        }
                    })),
                    API().put('/user/listing/stay', formData)
                ])
                    .then(() => history.push('/profile/artist-bnb'))
                    .finally(() => setSubmitting(false))
            }
        }
    });

    useEffect(() => {
        API().get('/listing/amenity')
            .then((res) => setExtendedAmenities(res.data))

        API().get('/listing/cancellation-policy')
            .then((res) => setCancellationPolicy(res.data?.cancellation_policies))
    }, [])

    const handleChangeImages = ({fileList: newFileList}) => setFileList(newFileList);

    const handleChangeMainImage = (image) => {
        if (image.file) {
            setMainPhoto(image.file);
            const reader = new FileReader();
            reader.onload = function (e) {
                setImageUrl(e.target.result);
            };
            reader.readAsDataURL(image.file);
        }
    };

    const handleRemoveMainImage = (e) => {
        e.stopPropagation();
        setMainPhoto('');
        setImageUrl('');
        if (mainImage.id) {
            setDeletedImages((p) => ([...p, mainImage.id]))
        }
    }

    const handleRemoveImage = (file) => {
        setDeletedImages((p) => ([...p, file.uid]))
    }

    const handleAddItem = (name) => () => {
        const list = [...values[name] || []];
        setFieldValue(name, [...list, {name: '', value: ''}])
    }

    const handleAddAmenities = () => {
        if (newAmenities?.length) {
            setExtendedAmenities((p) => ([...p, newAmenities]))
            setNewAmenities('')
        }
    }

    const {ref} = usePlacesWidget({
        apiKey: process.env.REACT_APP_MAPS_API_KEY,
        onPlaceSelected: (place) => {
            const {lat, lng} = place?.geometry.location;
            setFieldValue("latitude", lat());
            setFieldValue("longitude", lng());
            setFieldValue("address", place.formatted_address);
        },
    });

    function handleOpenProfileListings() {
        history.push('/profile/artist-bnb')
    }

    const handleDelete = () => {
        dispatch(deleteListingRequest())
        API().delete('/user/listing/stay', {params: {id}})
            .then(() => history.push('/profile/artist-bnb'))
            .catch(() => dispatch(deleteListingFailed))
    }

    return (
        <>
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.row}>
                    <div>
                        <Typography.Title type={errors.place_type ? 'danger' : undefined} level={4}>
                            Place type
                        </Typography.Title>
                        <div className={styles.subtitle}>Choose the option that describes your place best</div>
                    </div>
                    <Row gutter={[20, 20]}>
                        {places.map((i) => (
                            <Col xs={12} md={8} key={i}>
                                <StyledCheckbox
                                    name="place_type"
                                    checked={values.place_type === i}
                                    onChange={() => setFieldValue('place_type', i)}
                                >
                                    {i}
                                </StyledCheckbox>
                            </Col>
                        ))}
                    </Row>
                </div>
                <div className={styles.row}>
                    <div>
                        <Typography.Title level={4}>Basic information</Typography.Title>
                        <div className={styles.subtitle}>
                            Ensure to include a photo to provide a visual preview of your space. <br/>
                            Use high quality professional images or contact our support to help you enhance your listing
                            images
                        </div>
                        <div className={styles.imagesWrapper}>
                            <div className={styles.mainImageUpload}>
                                <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    showUploadList={false}
                                    beforeUpload={() => false}
                                    onChange={handleChangeMainImage}
                                >
                                    {imageUrl ? (
                                        <div className={styles.previewWrapper}>
                                            <img src={imageUrl} alt="avatar"/>
                                            <div className={styles.previewWrapperOverlay}>
                                                <IconButton
                                                    color="inherit"
                                                    icon={CloseIcon}
                                                    onClick={handleRemoveMainImage}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <Space direction="vertical">
                                            <UploadIcon/>
                                        </Space>
                                    )}
                                </Upload>
                            </div>
                            <div className={styles.images}>
                                <Upload
                                    multiple
                                    beforeUpload={() => false}
                                    showUploadList={{
                                        showPreviewIcon: false, showRemoveIcon: true, removeIcon: <CloseIconThin/>
                                    }}
                                    listType="picture-card"
                                    fileList={fileList}
                                    onRemove={handleRemoveImage}
                                    onChange={handleChangeImages}
                                >
                                    <UploadIcon/>
                                </Upload>
                            </div>
                        </div>
                    </div>
                    <Space direction="vertical">
                        <StyledCheckbox
                            error={errors.validateRentable}
                            checked={values.daily_rentable}
                            name="daily_rentable"
                            onChange={handleChange}
                        >
                            Daily rent
                        </StyledCheckbox>
                        <StyledCheckbox
                            error={errors.validateRentable}
                            checked={values.monthly_rentable}
                            name="monthly_rentable"
                            onChange={handleChange}
                        >
                            Monthly rent
                        </StyledCheckbox>
                    </Space>
                    <Row gutter={[24, 24]}>
                        <Col xs={24} md={8}>
                            <StyledInput
                                name="name"
                                label="Studio name"
                                status={errors.name ? 'error' : null}
                                value={values.name}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={24} md={8}>
                            <StyledInput
                                name="daily_price"
                                label="Price per night (€)"
                                status={errors.daily_price ? 'error' : null}
                                value={values.daily_price}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={24} md={8}>
                            <StyledInput
                                name="monthly_price"
                                label="Price per month (€)"
                                status={errors.monthly_price ? 'error' : null}
                                value={values.monthly_price}
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>
                    <div>
                        <div className={clsx(styles.mutedLabel)}>Studio location</div>
                        <TextInput
                            name="address"
                            ref={ref}
                            errTitle=" "
                            error={errors.address}
                            value={values.address}
                            onChange={handleChange}
                            className={styles.input}
                        />
                        <div className={styles.mapWrapper}>
                            <GoogleMap address={values.address}/>
                        </div>
                    </div>
                    <div>
                        <StyledInput
                            rows={4}
                            textarea
                            label="Description"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div>
                        <Typography.Title level={4}>Details</Typography.Title>
                        <div className={styles.subtitle}>
                            Add facts and figures to the base so that users can quickly evaluate your space
                        </div>
                    </div>
                    <Row justify="space-between">
                        <Col xs={24} md={11}>
                            <div className={styles.row}>
                                <div>
                                    <div className={styles.label}>
                                        Guests (number)
                                    </div>
                                    <Row justify="space-between" className={styles.inputWrapper}>
                                        <Col span={4}>
                                            <StyledInput
                                                centered
                                                name="guests_num_min"
                                                type="number"
                                                value={values.guests_num_min}
                                                size="small"
                                                label="min"
                                                onChange={handleChange}
                                            />
                                        </Col>
                                        <Col span={4}>
                                            <StyledInput
                                                centered
                                                name="guests_num_max"
                                                type="number"
                                                size="small"
                                                label="max"
                                                value={values.guests_num_max}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                    </Row>
                                    <StyledRangeSlider
                                        range
                                        tooltip={{
                                            open: false
                                        }}
                                        min={0}
                                        max={500}
                                        step={2}
                                        defaultValue={[0, 500]}
                                        onChange={(v) => setFieldValue('guests_num_min', v[0])}
                                        onAfterChange={(v) => setFieldValue('guests_num_max', v[1])}
                                    />
                                </div>
                                <div>
                                    <div className={clsx(styles.label, errors.rooms_num && styles.labelError)}>
                                        Rooms (number)
                                    </div>
                                    <Row justify="center" className={styles.inputWrapper}>
                                        <Col span={3}>
                                            <StyledInput
                                                centered
                                                size="small"
                                                name="rooms_num"
                                                value={values.rooms_num}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                    </Row>
                                    <StyledRangeSlider
                                        value={values.rooms_num}
                                        tooltip={{
                                            open: false
                                        }}
                                        onChange={(v) => setFieldValue('rooms_num', v)}
                                    />
                                </div>
                                <div>
                                    <div className={clsx(styles.label, errors.beds_num && styles.labelError)}>
                                        Beds (number)
                                    </div>
                                    <Row justify="center" className={styles.inputWrapper}>
                                        <Col span={3}>
                                            <StyledInput
                                                centered
                                                size="small"
                                                name="beds_num"
                                                value={values.beds_num}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                    </Row>
                                    <StyledRangeSlider
                                        value={values.beds_num}
                                        tooltip={{
                                            open: false
                                        }}
                                        onChange={(v) => setFieldValue('beds_num', v)}
                                    />
                                </div>
                                <div>
                                    <div className={clsx(styles.label, errors.baths_num && styles.labelError)}>
                                        Baths (number)
                                    </div>
                                    <Row justify="center" className={styles.inputWrapper}>
                                        <Col span={3}>
                                            <StyledInput
                                                centered
                                                size="small"
                                                name="baths_num"
                                                value={values.baths_num}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                    </Row>
                                    <StyledRangeSlider
                                        value={values.baths_num}
                                        tooltip={{
                                            open: false
                                        }}
                                        onChange={(v) => setFieldValue('baths_num', v)}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col sm={24} md={11}>
                            <div className={styles.row}>
                                <StyledInput
                                    name="area_sq_ft"
                                    type="number"
                                    status={errors.area_sq_ft ? 'error' : null}
                                    label="Studio Area (sq. ft.)"
                                    value={values.area_sq_ft}
                                    onChange={handleChange}
                                />
                                <Row gutter={[16, 16]}>
                                    <Col span={24}>
                                        <div className={styles.label}>
                                            <Typography.Text strong>Other information</Typography.Text>
                                            <QuestionMarkOutlinedIcon/>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <Row gutter={[16, 16]}>
                                            {values.other_infos?.map((info, i) => (<Fragment key={i}>
                                                <Col span={12}>
                                                    <StyledInput
                                                        label="Name"
                                                        value={values.other_infos[i].name}
                                                        name={`other_infos[${i}].name`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col span={12}>
                                                    <StyledInput
                                                        label="Value"
                                                        type="number"
                                                        value={values.other_infos[i].value}
                                                        name={`other_infos[${i}].value`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Fragment>))}
                                        </Row>
                                    </Col>
                                    <Col span={24}>
                                        <StyledButton
                                            type="text"
                                            color="none"
                                            className={styles.addItem}
                                            onClick={handleAddItem('other_infos')}
                                        >
                                            <PlusIconOutlined/>
                                            Add new details
                                        </StyledButton>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className={styles.row}>
                    <div>
                        <Typography.Title level={4}>Amenities</Typography.Title>
                        <div className={styles.subtitle}>Tell us what your place has to offer</div>
                    </div>
                    <Row gutter={24}>
                        {extendedAmenities.map((i) => (
                            <Col xs={12} md={8} key={i}>
                                <StyledCheckbox
                                    name='amenities'
                                    checked={values?.amenities?.includes(i)}
                                    value={i}
                                    onChange={handleChange}
                                >
                                    {i}
                                </StyledCheckbox>
                            </Col>
                        ))}
                    </Row>
                    <Row>
                        <Col xs={24} sm={12}>
                            <StyledInput
                                label="Other amenities"
                                value={newAmenities}
                                onChange={(e) => setNewAmenities(e.target.value)}
                            />
                        </Col>
                        <Col span={24}>
                            <StyledButton
                                type="text"
                                color="none"
                                className={styles.addItem}
                                onClick={handleAddAmenities}
                            >
                                <PlusIconOutlined/>
                                Add new option
                            </StyledButton>
                        </Col>
                    </Row>
                </div>
                <div className={styles.row}>
                    <div>
                        <Typography.Title level={4}>Calendar</Typography.Title>
                        <div className={styles.subtitle}>Customize the duration of availability</div>
                    </div>
                    <Row gutter={[24, 24]}>
                        <Col xs={24} lg={12}>
                            <div className={styles.mutedLabel}>Check-in time</div>
                            <div className={clsx(styles.timePicker, (errors.check_in_from_time || errors.check_in_to_time) && styles.timePickerError)}>
                                <StyledDatePicker
                                    type="transparent"
                                    showIcon={false}
                                    selected={getSelectedTimeValue(values.check_in_from_time)}
                                    onChange={(date) => setFieldValue('check_in_from_time', date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={60}
                                    timeCaption="Time"
                                    placeholderText="From"
                                    dateFormat="h:mmaa"
                                />
                                -
                                <StyledDatePicker
                                    type="transparent"
                                    showIcon={false}
                                    selected={getSelectedTimeValue(values.check_in_to_time)}
                                    onChange={(date) => setFieldValue('check_in_to_time', date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={60}
                                    timeCaption="Time"
                                    placeholderText="To"
                                    dateFormat="h:mmaa"
                                />
                            </div>
                        </Col>
                        <Col xs={24} lg={12}>
                            <div className={styles.mutedLabel}>Check-out time</div>
                            <div className={clsx(styles.timePicker, (errors.check_out_from_time || errors.check_out_to_time) && styles.timePickerError)}>
                                <StyledDatePicker
                                    type="transparent"
                                    showIcon={false}
                                    selected={getSelectedTimeValue(values.check_out_from_time)}
                                    onChange={(date) => setFieldValue('check_out_from_time', date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={60}
                                    timeCaption="Time"
                                    placeholderText="From"
                                    dateFormat="h:mmaa"
                                />
                                -
                                <StyledDatePicker
                                    type="transparent"
                                    showIcon={false}
                                    selected={getSelectedTimeValue(values.check_out_to_time)}
                                    onChange={(date) => setFieldValue('check_out_to_time', date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={60}
                                    timeCaption="Time"
                                    placeholderText="To"
                                    dateFormat="h:mmaa"
                                />
                            </div>
                        </Col>
                    </Row>
                    {id && (
                        <div>
                            <Link to={`/calendar-customization/${id}`}>
                                <StyledButton>
                                    Open Calendar Customization
                                </StyledButton>
                            </Link>
                        </div>
                    )}
                </div>
                <div className={styles.row}>
                    <div>
                        <Typography.Title level={4}>Your contacts</Typography.Title>
                        <div className={styles.subtitle}>
                            Provide alternative ways to contact you
                        </div>
                    </div>
                    <Row gutter={[24, 24]}>
                        <Col xs={24} lg={12}>
                            <StyledInput
                                name="owner_email"
                                label="Email"
                                value={values.owner_email}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={24} lg={12}>
                            <StyledInput
                                name="owner_phone"
                                label="Phone number"
                                value={values.owner_phone}
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>
                </div>
                <div className={styles.row}>
                    <div>
                        <Typography.Title level={4}>Cancellation policy</Typography.Title>
                        <div className={styles.subtitle}>Select the type of cancellation</div>
                    </div>
                    <Space direction="vertical" size={20}>
                        {cancellationPolicy.map((item) => (
                            <StyledCheckbox
                                key={item.id}
                                align="start"
                                name="cancellation_policy_id"
                                checked={values.cancellation_policy_id === item.id}
                                onChange={() => setFieldValue('cancellation_policy_id', item.id)}
                            >
                                <Space direction="vertical" size={5}>
                                    <Typography.Text strong>{item.title}</Typography.Text>
                                    <div className={styles.preWrap}>{item.text?.split('\\n').map((i) => <p>{i}</p>)}</div>
                                </Space>
                            </StyledCheckbox>
                        ))}
                    </Space>
                </div>
                <Row gutter={[16, 16]}>
                    {id && (
                        <Col xs={12} lg={6} xxl={5}>
                            <StyledButton block color="transparent" onClick={toggleDeleteModalOpen}>
                                Delete listing
                            </StyledButton>
                        </Col>
                    )}
                    <Col xs={id ? 12 : 8} lg={6} xxl={5}>
                        <StyledButton block color="transparent" onClick={handleOpenProfileListings}>
                            Cancel
                        </StyledButton>
                    </Col>
                    <Col xs={id ? 24 : 16} lg={6} xxl={5} className={styles.footerActionRight}>
                        <StyledButton block color="black" htmlType="submit" loading={isSubmitting}>
                            {id ? 'Save changes' : 'Create new listing'}
                        </StyledButton>
                    </Col>
                </Row>
            </form>
            <StyledModal
                okText="Yes, delete"
                cancelText="No"
                title="Are you sure you want to delete this listing?"
                open={isDeleteModelOpen}
                onCancel={toggleDeleteModalOpen}
                onOk={handleDelete}
                okButtonProps={{
                    disabled: isDeleting
                }}
            />
        </>
    )
}