import React, {useState} from "react";
import './styles/new_footer.css'
import footerLogo from '../assets/img/new_landing/footer_logo.svg'
import footer_medium from '../assets/img/new_landing/footer_medium.svg'
import footer_facebook from '../assets/img/new_landing/footer_facebook.svg'
import footer_linkedin from '../assets/img/new_landing/footer_linkedin.svg'
import footer_instagram from '../assets/img/new_landing/footer_instagram.svg'
import footer_twitter from '../assets/img/new_landing/footer_twitter.svg'
import footer_pinterest from '../assets/img/new_landing/footer_pinterest.svg'
import {Link} from "react-router-dom";
import MainButton from "./CustomComponents/MainButton";
import RegionalSettingsPopup from "./RegionalSettingsPopup";
import {connect} from "react-redux";
import {setCurrency, setUnits} from "../main-store/auth/actions";
import {unitByCurrency} from "../Constants/unitByCurrency";

const footerInfo = {
    'For Investors': [
        {
            value: "Collectors Hub",
            link: '/collectors-hub'
        },
        {
            value: "Buyer Faq",
            link: '/premium#buyer_faq'
        },
        // {
        //     value: "Testimonials",
        //     link: '/premium#testimonials'
        // },

        // {
        //     value: "Testimonials",
        //     link: '/info/testimonials'
        // },

        {
            value: "Artsted for businesses",
            link: '/artsted-for-businesses'
        },
        {
            value: "Artsted for professionals",
            link: '/artsted-for-professionals'
        }
    ],
    'For Artists': [
        {
            value: "Why Sell",
            link: '/premium#why_sell'
        },
        {
            value: "Artist Handbook",
            link: '/premium#artist_handbook'
        },
        {
            value: "How Does It Work",
            link: '/premium#how_does_it_work'
        }
    ],
    'About Us': [
        {
            value: "Press",
            link: '/premium#press'
        },
        {
            value: "Careers",
            link: '/premium#careers'
        },
        {
            value: "Contact Us",
            link: '/premium#contact_us'
        },
        {
            value: "Blog",
            link: null,
            href: 'https://blog.artsted.com'
        },
        {
            value: "FAQ",
            link: '/faq/what_is_artsted'
        },
    ],
    'Artisfact': [
        {
            value: "Privacy Policy",
            link: '/info/privacy_policy/important_information'
        },
        {
            value: "Terms Of Service",
            link: '/info/terms_of_service/applicability'
        },
        {
            value: "Copiright Policy",
            link: '/premium#copyright_policy'
        },
        // {
        //     value: "Affiliate Program",
        //     link: '/premium#affiliate_program'
        // }
    ],
}

const footerSocial = [
    {
        image: footer_linkedin,
        link: 'https://www.linkedin.com/company/artisfact-limited',
    },
    {
        image: footer_facebook,
        link: 'https://www.facebook.com/artstedcom',
    },
    {
        image: footer_instagram,
        link: 'https://www.instagram.com/artstedcom',
    },
    {
        image: footer_medium,
        link: 'https://artstedcom.medium.com',
    },
    {
        image: footer_twitter,
        link: 'https://twitter.com/artsted1',
    },
    {
        image: footer_pinterest,
        link: 'https://www.pinterest.com/Artsted_online',
    },
]

function NewFooter({currency, units, setCurrency, setUnits}) {
    const [regionalSettingsPopup, setRegionalSettingsPopup] = useState(false);

    const openRegionalSettingsPopup = () => setRegionalSettingsPopup(true)

    const closeRegionalSettingsPopup = () => setRegionalSettingsPopup(false)

    return (
        <footer className={'footer'}>
            <div className={'footer_information'}>
                <div className={'navigation'}>
                    {Object.entries(footerInfo).map((el, i) => (
                        <div className={'navigation_item'} key={i}>
                            {el[0] === "About Us" ? <a><h3>{el[0]}</h3></a> : <h3>{el[0]}</h3>}
                            <div className={'navigation_options'}>
                                {el[1].map((item, index) => [
                                    item.link
                                        ? <Link to={item.link} key={index}>{item.value}</Link>
                                        : <a href={item.href} key={index}>{item.value}</a>
                                ])}
                            </div>
                        </div>
                    ))}
                </div>
                <div className={'navigation_item regional-settings'}>
                    <h3>Your regional settings</h3>
                    <div className={'navigation_options'}>
                        <div>
                            <span className="key">Currency: </span>
                            <span className="value">{currency || "EUR"} ({unitByCurrency[currency] || "€"})</span>
                        </div>
                        <div>
                            <span className="key">Display Units: </span>
                            <span className="value">{units}</span>
                        </div>
                    </div>
                    <MainButton
                        style={{width: '100%'}}
                        className={'button_white'}
                        text={"Change my settings"}
                        onClick={openRegionalSettingsPopup}/>
                </div>
            </div>

            {/*{innerWidth <= 650 ?*/}
            {/*    <a className={'artsted_digital_link'} href={'/'}>Artsted Digital ></a> */}
            {/*    : null}*/}

            <div className="footer-bottom">
                <Link to={'/'} className={'footer_logotype'}>
                    <img src={footerLogo} alt="logo"/>
                </Link>
                <div className={'social_wrapper'}>
                    <div className={'footer_social_links'}>
                        {footerSocial.map((el, i) => (
                            <a key={i} href={el.link}>
                                <img src={el.image} alt="social link"/>
                            </a>
                        ))}
                    </div>
                </div>
            </div>

            <span className="copyright">
                Artisfact limited, © 2023
            </span>

            <RegionalSettingsPopup
                visible={regionalSettingsPopup}
                closePopup={closeRegionalSettingsPopup}
                units={units}
                currency={currency}
                setUnits={setUnits}
                setCurrency={setCurrency}
            />
        </footer>
    )
}

const mapStateToProps = state => ({
    currency: state.auth.currency,
    units: state.auth.units
})

const putDispatchToProps = {
    setCurrency,
    setUnits
}

export default connect(mapStateToProps, putDispatchToProps)(NewFooter);
