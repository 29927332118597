import React, {useMemo} from "react";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import MainButton from "./CustomComponents/MainButton";
import {capitalizeFirstLetter} from "../Constants/capitalizeFirstLetter";
import otherConst from "../Constants/otherConst";

const CustomTooltip = (props) => {
    let toolTipEvents = props.data?.find(i => i.date === props.label);
    return toolTipEvents ?
        <div className="customizeToolTip customizeToolTipAvgDate">
            <span className="date">{toolTipEvents.price}€</span>
        </div>
        : null;
};

const CustomizedDot = (props) => {
    const {cx, cy} = props;
    return (
        <svg width="17" x={cx - 10} y={cy - 10}
             cursor={'pointer'}
             height="18" viewBox="0 0 17 18" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <rect y="9" width="12" height="12" transform="rotate(-45 0 9)" fill="black"/>
        </svg>
    )
}
const CustomizedDotDisable = () => {
    return (
        <div style={{display: 'none'}}>
        </div>
    )
}

const NewStatisticArtistPrices = ({loading, data, innerWidth, setActiveTab, activeTab}) => {

    const monthReplace = month => {
        let activeMonth = null;
        otherConst.months.forEach((m, index) => {
            if (m.prev === month) {
                if (!otherConst.months[index - 1]){
                    return
                }
                activeMonth = otherConst.months[index - 1].prev
            }
        })
        return activeMonth
    }

    const activeDate = (date, int) => {
        if (int) {
            return monthReplace(int)
        } else {
            let actData = date.split(' ')
            actData[1] = monthReplace(actData[1])
            return actData.join(' ')
        }
    }

    const tabCondition = arr => {
        if (activeTab.priceChart.value !== 'all_time') {
            let lastDate = arr[arr.length - 1].date.split(' ')[0]
            return arr.map(i => {
                if (i.date.split(' ')[0] === lastDate) {
                    i.date = i.date.split(' ')[1]
                    return i
                } else {
                    return null
                }
            }).filter(Boolean)
        } else {
            return arr
        }
    }

    const priceChartsArray = useMemo(() => {
        if (loading && data.schedule?.length) {
            let protoData = JSON.parse(JSON.stringify(data.schedule));
            if (Object.entries(protoData).length === 1) {
                let protoArray = JSON.parse(JSON.stringify(protoData));
                protoArray[1] = JSON.parse(JSON.stringify(protoArray[0]))
                protoArray[0].price = 0;
                protoArray[0].price_sold = 0;
                protoArray[0].date = activeDate(protoArray[1].date)
                return tabCondition(protoArray)
            } else {
                return tabCondition(protoData)
            }
        }else {
            return []
        }
        // eslint-disable-next-line
    }, [data.schedule, loading, activeTab.priceChart.value])


    return (
        <div className={'statistic_profile_views'}>
            <div className="title">
                <span>Price change</span>
            </div>
            {priceChartsArray?.length ?
                <div className={'wrapper_tabs'}>
                    <div className={'active_tabs_container'}>
                        {activeTab.priceChart.options.map((el, i) => (
                            <MainButton type={'button'}
                                        key={i}
                                        text={capitalizeFirstLetter(el.split('_').join(' '))}
                                        style={activeTab.priceChart.value === el ? {
                                            background: '#000',
                                            color: '#fff'
                                        } : null}
                                        className={'button_black'}
                                        onClick={() => setActiveTab(prevState => ({
                                            ...prevState,
                                            priceChart: {
                                                ...prevState.priceChart,
                                                value: el
                                            }
                                        }))}
                            />
                        ))}
                    </div>
                </div>
                : null}
            {loading && priceChartsArray?.length ?
                <div className={'wrapper_statistic_prices_container'}>
                    <div className={'wrapper_statistic_prices__chart'}>
                        <ResponsiveContainer width={innerWidth < 600 ? 600 : '99%'} height={400}>
                            <LineChart data={priceChartsArray}
                                       margin={{
                                           top: 12,
                                           right: 20,
                                           left: -12,
                                           bottom: 5
                                       }}>
                                <CartesianGrid vertical={false}/>
                                <YAxis dataKey="price"/>
                                <XAxis dataKey="date"/>
                                <Tooltip content={<CustomTooltip data={priceChartsArray}/>}/>
                                <Tooltip/>
                                <Line strokeWidth={2}
                                      activeDot={<CustomizedDot/>}
                                      dot={false}
                                      dataKey="price"
                                      stroke="#000"/>
                                <Line activeDot={<CustomizedDotDisable/>}
                                      strokeWidth={2}
                                      dot={false}
                                      dataKey="price_sold"
                                      stroke="#ccc"/>
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                    <div className={'wrapper_statistic_prices__info'}>
                        <div className={'statistic_wrapper__area_legend'}>
                            <div className={'every'}>
                                <div className={'color'} style={{background: '#000'}}/>
                                <span className={'title'}>Average price of uploaded artworks</span>
                            </div>
                            <div className={'every'}>
                                <div className={'color'} style={{background: '#ccc'}}/>
                                <span className={'title'}>Average sold price</span>
                            </div>
                        </div>
                        <div className={'statistic_wrapper__area_avg'}>
                            <div className={'every'}>
                                <span className={'preview'}>MIN</span>
                                <span className={'value'}>{data.min}€</span>
                            </div>
                            <div className={'every'}>
                                <span className={'preview'}>AVG</span>
                                <span className={'value'}>{data.avg}€</span>
                            </div>
                            <div className={'every'}>
                                <span className={'preview'}>MAX</span>
                                <span className={'value'}>{data.max}€</span>
                            </div>
                        </div>
                    </div>
                </div>
                : <span>No data yet</span>}
        </div>
    )
}

export default NewStatisticArtistPrices;
