import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import Wrapper from '../OnboardingWrapper';
import API from '../../../api/API';
import Input from '../../../Components/CustomComponents/Onboarding/Input';
import Button from '../../../Components/CustomComponents/Onboarding/Button';
import classes from './styles/welcome.module.scss';
import {clearCookies} from '../../../Constants/clearCookies';
import Keys from "../../../Constants/helper";
import regExp from '../../../Constants/regExp';
import Error from '../../../Components/CustomComponents/Onboarding/Error';
import {useDispatch, useSelector} from 'react-redux';
import {setUserRegistrationData} from '../../../main-store/auth/actions';
import historyDetectBack from "../HistoryBackDetect";
import {Loader} from "../../../Components/Loader";
import ModalWindow from "../../../Components/ModalWindow";
import MainButton from "../../../Components/CustomComponents/MainButton";
import {bodyOverflow} from "../../../Constants/bodyOverflow";
import {Apple} from "../../../Components/Apple";
import Facebook from "../../../Components/Facebook";

const ROLE = 'investor';

function Welcome() {
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPass] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [showErrors, setVisibilityErrors] = useState(false);
    const [artImgWelcome, setArtImgWelcome] = useState(null);
    const [facebookLogin, setFacebookLogin] = useState(false)
    const [showAppleButton, setShowAppleButton] = useState(true)
    // const [fbToken, setFbToken] = useState(null);
    const store = useSelector(state => state.auth)
    const history = useHistory();
    const dispatch = useDispatch();

    // const resetAuthData = () => {
    //     // setFbToken(null)
    //     sessionStorage.removeItem(Keys.VERIFIED_POPUP)
    //     sessionStorage.removeItem(Keys.LANDING_ROLE)
    // }

    // const loginSuccess = res => {
    //     if (res.accessToken) {
    //         // setFbToken(res.accessToken)
    //         dispatch(setAuthVariant(true));
    //         API().post(`/user/oauth`, {"token": res.accessToken, "role": ROLE})
    //             .then(res => {
    //                 localStorage.clear();
    //                 clearCookies();
    //                 let userData = JSON.stringify({
    //                     name: res.data.name,
    //                     role: ROLE,
    //                     avatar: res.data.avatar ? res.data.avatar : null
    //                 })
    //                 localStorage.setItem(Keys.JWT_TOKEN_REGISTRATION, res.data.access_token)
    //                 localStorage.setItem(Keys.USER_DATA, userData)
    //
    //                 // localStorage.setItem(Keys.JWT_TOKEN_REGISTRATION, res.data.access_token)
    //                 // localStorage.setItem(Keys.USER_DATA, userData)
    //                 dispatch(setUserData(res.data))
    //                 // window.location.pathname = '/main-info'
    //                 resetAuthData()
    //             })
    //             .catch((er) => console.log(er))
    //     }
    // }

    useEffect(() => {
        API().get(`/admin/landing`)
            .then(res => {
                if (res.data) {
                    setArtImgWelcome(res.data.artworks_top[Math.floor(Math.random() * res.data.artworks_top.length)])
                    setLoading(true)
                }
            })
        getStorageValues()
    }, [])

    useEffect(() => {
        if (!store.user_registration_data) {
            history.push('/registration/type')
        }
    }, [store.user_registration_data, history])

    useEffect(() => {
        if (showErrors) {
            _isFormValid();
        }
        // eslint-disable-next-line
    }, [name, surname, email, password, confirmPassword]);

    const _isFormValid = () => {
        const errors = {};

        if (!name) {
            errors.name = "Empty";
        }

        if (!surname) {
            errors.surname = "Empty";
        }

        if (!email) {
            errors.email = "Should not be empty";
        } else if (!regExp.email.test(email)) {
            errors.email = "Enter valid email";
        }

        if (password.length < 6) {
            errors.password = "Enter at least 6 characters";
        }

        if (!confirmPassword) {
            errors.confirm = "Should not be empty";
        } else if (password !== confirmPassword) {
            errors.confirm = "Password is not similar";
        }

        if (Object.keys(errors).length) {
            setErrors(errors);
            return false;
        } else {
            setErrors({});
        }

        return true;
    }

    const getStorageValues = () => {
        const platform = localStorage.getItem(Keys.APP_PLATFORM)
        console.log(platform, "platform")
        setShowAppleButton(platform !== "android")
    }

    const _onSubmit = (e, token) => {
        e?.preventDefault();

        if (!_isFormValid()) {
            setVisibilityErrors(true);
            return;
        }

        API().post('/user/registration/check_email', {email})
            .then(response => {
                if (response.data.result) {
                    // eslint-disable-next-line
                    throw 'The email is already in use';
                }

                return API().post(`/user/registration/email`, {
                    name,
                    surname,
                    email,
                    password,
                    role: ROLE,
                    receive_email: false,
                    apple_token: token
                })
            })
            .then(res => {
                const fromApp = localStorage.getItem(Keys.FROM_APP)
                const platform = localStorage.getItem(Keys.APP_PLATFORM)
                localStorage.clear();
                clearCookies();
                localStorage.setItem(Keys.FROM_APP, fromApp)
                localStorage.setItem(Keys.APP_PLATFORM, platform)
                localStorage.setItem(Keys.JWT_TOKEN_REGISTRATION, res.data.access_token)
                dispatch(setUserRegistrationData({
                    ...store.user_registration_data,
                    name,
                    surname,
                    email,
                    receive_email: false
                }))
                let {nextHistory} = historyDetectBack(store.user_registration_data, store.active_history)
                history.push(nextHistory)
            })
            .catch(e => {
                if (e === 'The email is already in use') {
                    setErrors({email: e});
                }
            });
    }

    const registerWithApple = async (token, user) => {
        try {
            const response = await API().post("/user/registration/email", {
                role: ROLE,
                receive_email: false,
                apple_token: token,
                name: user?.name.firstName,
                surname: user?.name.lastName
            })
            dispatch(setUserRegistrationData({
                ...store.user_registration_data,
                name: user?.name.firstName,
                surname: user?.name.lastName,
                email: user?.email,
                receive_email: false
            }))
            const fromApp = localStorage.getItem(Keys.FROM_APP)
            const platform = localStorage.getItem(Keys.APP_PLATFORM)
            localStorage.clear()
            localStorage.setItem(Keys.FROM_APP, fromApp)
            localStorage.setItem(Keys.APP_PLATFORM, platform)
            localStorage.setItem(Keys.JWT_TOKEN_REGISTRATION, response.data.access_token)
            let {nextHistory} = historyDetectBack(store.user_registration_data, store.active_history)
            history.push(nextHistory)
        } catch (e) {
            console.log(e.response, "error")
            if(e.response.data.error === "user already exist") {
                setErrors({email: e.response.data.error});
            }

        }
    }

    return (
        <Wrapper>
            <Wrapper.Form>
                <div className={classes.wrapper}>
                    {facebookLogin &&
                    <div key={35} className={'new_modal_wrapper'}>
                        <ModalWindow>
                            <h4>You can’t log in with facebook</h4>
                            <p>Your facebook account doesn’t have an e-mail address.</p>
                            <div className={'modal-window_buttons'}>
                                <div></div>
                                <MainButton type={'button'}
                                            style={{background: '#000', color: '#fff'}}
                                            className={'button_black'}
                                            text={'Cancel'}
                                            onClick={() => {
                                                setFacebookLogin(false)
                                                bodyOverflow(false)
                                            }}
                                />
                            </div>
                        </ModalWindow>
                    </div>
                    }
                    <h1 className={classes.title}>Welcome to Artsted!</h1>
                    <div>
                        <Facebook
                            setFacebookLogin={setFacebookLogin}
                            registration={true}
                        />
                        {showAppleButton && <Apple onSuccess={registerWithApple} />}
                    </div>
                    <form className={classes.form} onSubmit={_onSubmit}>
                        <div className={classes.form_row_2}>
                            <div className={classes.form_col}>
                                <Input
                                    className={classes.form_input}
                                    title="Name"
                                    value={name}
                                    onChange={({target}) => setName(target.value.trim())}
                                    error={errors.name}
                                />
                            </div>
                            <div className={classes.form_col}>
                                <Input
                                    className={classes.form_input}
                                    title="Surname"
                                    value={surname}
                                    onChange={({target}) => setSurname(target.value.trim())}
                                    error={errors.surname}
                                />
                            </div>
                        </div>
                        <Input
                            className={classes.form_input}
                            title="E-Mail"
                            value={email}
                            onChange={({target}) => setEmail(target.value.trim())}
                            error={errors.email}
                        />
                        <Input
                            className={classes.form_input}
                            title="Password"
                            type="password"
                            value={password}
                            onChange={({target}) => setPassword(target.value.trim())}
                            error={errors.password}
                        />
                        <Input
                            className={classes.form_input}
                            title="Confirm Password"
                            type="password"
                            value={confirmPassword}
                            onChange={({target}) => setConfirmPass(target.value.trim())}
                            error={errors.confirm}
                        />
                        <div className={classes.errors}>
                            {Object.keys(errors).map(key => {
                                return <Error text={errors[key]} key={key}/>
                            })}
                        </div>
                        <div>
                            <div className={classes.investor_registration_button_container}>
                                <div className={classes.footer_btns}>
                                    <Button
                                        type="submit"
                                        onClick={_onSubmit}
                                        disabled={regExp.email.test(email)}
                                    >
                                        Continue &#62;
                                    </Button>
                                </div>
                            </div>
                            {/*<Social className={classes.footer_social}>*/}
                            {/*    /!*<Social.Item icon={gmailIcon} text="Register with Gmail" onClick={null} />*!/*/}
                            {/*    <FacebookLogin*/}
                            {/*        appId="2618139161816939"*/}
                            {/*        autoLoad={false}*/}
                            {/*        fields="name,email,picture"*/}
                            {/*        callback={loginSuccess}*/}
                            {/*        render={renderProps => <Social.Item type="fb" icon={fbIcon}*/}
                            {/*                                            text="Register with Facebook"*/}
                            {/*                                            onClick={renderProps.onClick}/>}*/}
                            {/*    />*/}
                            {/*    /!*<Social.Item icon={hederalIcon} text="Register with Hedera" onClick={null} />*!/*/}
                            {/*</Social>*/}
                        </div>
                    </form>
                </div>
            </Wrapper.Form>
            {artImgWelcome && loading ?
                <Wrapper.Picture src={artImgWelcome.photo} imgRight artAuthor={artImgWelcome.user.name}
                                 authorAvatar={artImgWelcome.user.avatar}/>
                : <Loader color={{
                    '--bgColor': '#000',
                    '--size': '48px',
                    '--wrapper_size': '40px'
                }}/>}
        </Wrapper>
    );
}

export default Welcome;
