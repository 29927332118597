import React, {useState} from "react";
import './styles/formTemplate.css'
import API from "../../api/API";
import FormInput from "./FormInput";
import {setActiveUpload} from "../../main-store/auth/actions";
import useBottomPopup from "../../Constants/useBottomPopup";
import {useDispatch} from "react-redux";
import ChangedConfirmedPopup from "../CustomComponents/ChangedConfirmedPopup";


const FormTemplate = (props) => {
    const [errorMess, setErrorMess] = useState(false)
    const initialState = {
        name: {
            value: '',
            error: 'Fill this label'
        },
        surname: {
            value: '',
            error: 'Fill this label'
        },
        company: {
            value: '',
            error: 'Fill this label'
        },
        phone: {
            value: '',
            error: 'Fill this label'
        },
        position: {
            value: '',
            error: props?.type === 'For professionals' ? 'Fill this label' : ''
        },
        email: {
            value: '',
            error: 'Fill this label'
        },
        message: {
            value: '',
            error: 'Fill this label'
        },
    }
    const [formData, setFormData] = useState(initialState)
    let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();
    const uploadDispatch = useDispatch();

    const checkEmail = (data) => {
        const emailVal = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (data === '' || data === undefined) return 'Email field is required'
        if (!data.match(emailVal)) return 'Wrong email'
        return ''
    }

    const checkPhoneNumber = (data) => {
        const phoneVal = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

        if (data === '' || data === undefined) return'Phone field is required'
        if (!data.match(phoneVal)) return 'Wrong phone number'
        return ''
    }

    const checkOnEmpty = (data) => {
        if (data === '' || data === undefined) return 'This field is required'
        else return ''
    }


    const onSubmit = () => {
        console.log('Im here!!!!!!!')
        console.log('initialState: ', initialState)
        if (Object.values(formData).map(({error}) => error).filter( error => error !== '').length === 0) {
            const sendObj = {type: props?.type}
            for (const property in formData) sendObj[property] = formData[property].value

            API().post('/lets_have_chat', sendObj)
                .then(res => {
                    if (res.status === 200) {
                    }
                    changeContinuePopup('Your message was sent successfully')
                    setTimeout(() => {
                        changeContinuePopup('Your message was sent successfully')
                        // window.scrollTo(0, 0)
                        uploadDispatch(setActiveUpload(false))
                    }, 1000)
                })
            setErrorMess(false)
            setFormData(initialState)
        }
        else setErrorMess(true)

    }

    const saveFormData = (e, objectName, checkForError) => {
        let error = checkForError(e.target.value)
        setFormData(prevState => {
            return {...prevState, [objectName]: { value: e.target.value, error: error}}
        })
    }

    return (
        <div id={'form_template_wrapper'} className={'form_wrapper'}>
            <ChangedConfirmedPopup successText={successText}
                                   key={1}
                                   changingConfirmed={changingConfirmed}/>
            <h1 className={'form_title'}>Let’s have a chat!</h1>
            <FormInput
                obj={formData.name}
                checkForError={checkOnEmpty}
                showErrorMsg={errorMess}
                title={'Name'}
                placeholder={'Name'}
                objectName={'name'}
                onChange={saveFormData}
            />
            <FormInput
                obj={formData.surname}
                checkForError={checkOnEmpty}
                showErrorMsg={errorMess}
                title={'Surname'}
                placeholder={'Surname'}
                objectName={'surname'}
                onChange={saveFormData}
            />
            {props?.type === 'For professionals' &&
            <FormInput
                obj={formData.position}
                checkForError={checkOnEmpty}
                showErrorMsg={errorMess}
                title={'Position'}
                placeholder={'Position'}
                objectName={'position'}
                onChange={saveFormData}
            />
            }
            <FormInput
                obj={formData.company}
                checkForError={checkOnEmpty}
                showErrorMsg={errorMess}
                title={'Company'}
                placeholder={'Company'}
                objectName={'company'}
                onChange={saveFormData}
            />
            <FormInput
                obj={formData.phone}
                checkForError={checkPhoneNumber}
                showErrorMsg={errorMess}
                title={'Phone number'}
                placeholder={'Phone Name'}
                objectName={'phone'}
                onChange={saveFormData}
            />
            <FormInput
                obj={formData.email}
                checkForError={checkEmail}
                showErrorMsg={errorMess}
                title={'Email'}
                placeholder={'Email'}
                objectName={'email'}
                onChange={saveFormData}
            />
            <FormInput
                obj={formData.message}
                checkForError={checkOnEmpty}
                showErrorMsg={errorMess}
                title={'Message'}
                placeholder={'Message'}
                objectName={'message'}
                onChange={saveFormData}
            />
            <div onClick={onSubmit} className={'send_button'}>
                <span>Send</span>
            </div>
        </div>
    )
}

export default FormTemplate
