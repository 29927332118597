import React, {useMemo} from "react";
import NewStatisticProfileViews from "./ProfileComponents/NewStatisticProfileViews";
import NewStatisticCategoryViews from "./ProfileComponents/NewStatisticCategoryViews";
import NewStatisticPerformance from "./ProfileComponents/NewStatisticPerformance";
import NewStatisticArtistActivity from "./NewStatisticArtistActivity";
import NewStatisticRegionViews from "./ProfileComponents/NewStatisticRegionViews";
import {capitalizeFirstLetter} from "../Constants/capitalizeFirstLetter";
import MainButton from "./CustomComponents/MainButton";
import ToolTipComponent from "./ToolTipComponent";
import NewStatisticArtistPrices from "./NewStatisticArtistPrices";

const PublicStatistic = props => {
    let legend = [
        {color: '#E1DCDC', title: 'Awards'},
        {color: '#6D9199', title: 'Museums'},
        {color: '#5B5757', title: 'Media'},
        {color: '#363434', title: 'Exhibitions'}
    ]
    const {
        loading, views_by_region,
        views, performance, most_popular_categories, innerWidth,
        barChart, performanceScore, priceChart,
        setActiveTab, activeTab, user: {full_name},
        profileInfo
    } = props;

    const barChartsArray = useMemo(() => {
        let arr = []
        if (loading && Object.keys(barChart)?.length) {
            if (activeTab.barChart.value === 'all_time') {
                Object.entries(barChart).forEach(i => ({
                    by_month: i[1].by_month.forEach(m => {
                        arr.push(
                            {
                                ...m,
                                month: `${i[0]} ${m.month}`,
                                total_score: [m.awards_score, m.exhibitions_score, m.media_score, m.museums_score].reduce((a, b) => a + b)
                            })
                    })
                }))
                return arr
            } else {
                Object.entries(barChart).forEach((i, index) => {
                    if (index === Object.keys(barChart).length - 1) {
                        i[1].by_month.forEach(m => {
                            arr.push(
                                {
                                    ...m,
                                    total_score: [m.awards_score, m.exhibitions_score, m.media_score, m.museums_score].reduce((a, b) => a + b)
                                })
                        })
                    }
                })
                return arr
            }
        }

    }, [barChart, loading, activeTab.barChart.value])


    return (
        <div className={'new_statistic new_statistic_public'}>
            <div className={'wrapper_statistic_views'}>
                <NewStatisticProfileViews loading={loading}
                                          profile={'This'}
                                          data={views}/>
                <NewStatisticCategoryViews innerWidth={innerWidth}
                                           loading={loading}
                                           data={most_popular_categories}/>
                <section className={'pre_footer'}>
                    <div>
                        <span className="title">{profileInfo.loading && profileInfo.user_statistic.views}</span>
                        <span className="description">Views</span>
                    </div>
                    <div>
                    <span
                        className="title">{profileInfo.loading && profileInfo.user_statistic.collectors_interested}</span>
                        <span className="description">Collectors interested</span>
                    </div>
                    <div>
                        <span className="title">{profileInfo.loading && profileInfo.user_statistic.sales}</span>
                        <span className="description">Sales</span>
                    </div>
                    <div>
                        <span className="title">{profileInfo.loading && profileInfo.user_statistic.total_exhibitions}</span>
                        <span className="description">Career points</span>
                    </div>
                </section>
            </div>
            <div className="wrapper_artist_activity statistic_profile_views">
                <div className="preview_wrapper">
                    <div className={'title'}>
                        <span>Artist Activity</span>
                        <ToolTipComponent link={'/premium#artists_career_tracking'}
                                          text={'Artists’ Career Tracking'}/>
                    </div>
                    <div className={'description'}>
                        <span>Performance score: </span>
                        <span className={'score'}>{performanceScore}</span>
                        <ToolTipComponent link={'/premium#pricing'}
                                          text={'Pricing'}/>
                    </div>
                </div>
                {barChartsArray?.length ?
                    <div className={'wrapper_tabs'}>
                        <div className={'active_tabs_container'}>
                            {activeTab.barChart.options.map((el, i) => (
                                <MainButton type={'button'}
                                            key={i}
                                            text={capitalizeFirstLetter(el.split('_').join(' '))}
                                            style={activeTab.barChart.value === el ? {
                                                background: '#000',
                                                color: '#fff'
                                            } : null}
                                            className={'button_black'}
                                            onClick={() => setActiveTab(prevState => ({
                                                ...prevState,
                                                barChart: {
                                                    ...prevState.barChart,
                                                    value: el
                                                }
                                            }))}
                                />
                            ))}
                        </div>
                    </div>
                    : null}
                <NewStatisticArtistActivity data={barChartsArray}
                                            barChart={barChart}/>
                {barChartsArray?.length ?
                    <div className="wrapper_legend">
                        {legend.map((i, index) => (
                            <div className={'legend_every'} key={index}>
                                <div style={{background: i.color}}/>
                                <span>{i.title}</span>
                            </div>
                        ))}
                    </div>
                    : null}
            </div>
            <div className={'wrapper_statistic_performance'}>
                <NewStatisticPerformance data={performance}
                                         title={'Performance'}
                                         full_name={full_name}
                                         publicSection={true}
                                         innerWidth={innerWidth}
                                         loading={loading}/>
            </div>
            <div className={'wrapper_statistic_prices'}>
                <NewStatisticArtistPrices data={priceChart}
                                          title={'Price Change'}
                                          setActiveTab={e => setActiveTab(e)}
                                          activeTab={activeTab}
                                          innerWidth={innerWidth}
                                          loading={loading}/>
            </div>
            <div className={'wrapper_statistic_region'}>
                <NewStatisticRegionViews data={views_by_region}
                                         innerWidth={innerWidth}
                                         loading={loading}/>
            </div>
        </div>
    )
}

export default PublicStatistic
