import clsx from "clsx";
import moment from "moment";
import React, {useCallback, useEffect, useReducer, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import {Col, Row, Typography} from "antd";
import {useHistory} from "react-router-dom";

import API from "../../api/API";
import useResize from "../../Constants/useResize";
import {
    getListingAmenitiesFailed,
    getListingsFailed,
    getListingsReceived,
    getListingsRequest,
} from "../../main-store/listing/actions";

import "../styles/listings.css"
import ExperiencesFilters from "../../Components/experiences/ExperiencesFilters/ExperiencesFilters";
import ExperiencesMap from "../../Components/experiences/ExperiencesMap";
import ExperiencesStayItem from "../../Components/experiences/ExperiencesStayItem/ExperiencesStayItem";
import NewPaginationView from "../../Components/NewPaginationView";
import IncrementDecrementInput from "../../Components/ui/IncrementDecrementInput";
import StyledTabs from "../../Components/ui/StyledTabs";
import StyledButton from "../../Components/ui/StyledButton";
import IconButton from "../../Components/ui/IconButton";
import StyledDatePicker from "../../Components/ui/StyledDatePicker";
import {Loader} from "../../Components/Loader";
import AutocompleteTextInput from "../../Components/CustomComponents/AutocompleteTextInput";
import MainButton from "../../Components/CustomComponents/MainButton";

import {ChevronLeft, Filter2Icon, FilterIcon, ListIcon, MapIcon} from "../../assets/icons";

import styles from './Experiences.module.css'

const initialFilters = {
    daily_rentable: null,
    monthly_rentable: null,
    guests_num: undefined,
    amenities: [],
    place_types: [],
    daily_price_from: 0,
    daily_price_to: 1000,
}

const items = [
    {key: 'studio', label: 'Studios'},
    {key: 'event', label: 'Events'},
    {key: 'stay', label: 'Stays'},
];

export default function Experiences() {
    const dispatch = useDispatch();
    const {category} = useParams();
    const {innerWidth} = useResize();
    const history = useHistory();

    const [tab, setTab] = useState(0);
    const [page, setPage] = useState(1);
    const [address, setAddress] = useState(null);
    const [filtersData, setFiltersData] = useState(initialFilters);
    const {pages = 0, loading, listings} = useSelector(state => state.listings);
    const [isFiltersOpen, toggleIsFiltersOpen] = useReducer(state => !state, false);

    const handleClearFilters = useCallback(() => {
        setFiltersData(initialFilters);
        setAddress('');
    }, []);

    const handleChangeFilters = useCallback((data) => {
        setFiltersData(data);
        setPage(1);
    }, []);

    const handleChangeAddress = useCallback((e) => {
        if (!e.target.value) {
            setFiltersData((p) => ({...p, address: null}))
        }
        setAddress(e.target.value)
    }, []);

    const handleChangeGuests = useCallback((guests_num) => {
        setPage(1);
        setFiltersData((p) => ({...p, guests_num}))
    }, []);

    const handleSelectAddress = useCallback((address) => {
        setPage(1);
        setAddress(address);
        setFiltersData((p) => ({...p, address}))
    }, []);

    const handleChangeDate = useCallback(([date_from, date_to]) => {
        setFiltersData((p) => ({...p, date_from, date_to}))
    }, []);

    const handleChangePage = useCallback((p) => {
        setPage(p);
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, []);

    const handleAddListing = () => {
        history.push('/profile/artist-bnb')
    }

    useEffect(() => {
        API().get('/listing/amenity')
            .then((res) => dispatch(getListingAmenitiesFailed(res.data)))
    }, []);

    useEffect(() => {
        if (category) {
            dispatch(getListingsRequest())
            API().get('/listing/list', {
                params: {
                    type: category.replace(/^\w/, l => l?.toUpperCase()),
                    ...filtersData,
                    amenities: filtersData.amenities.join(','),
                    place_types: filtersData.place_types.join(','),
                    sort_by: filtersData.sort_by_type ? 'daily_price' : null,
                    date_from: filtersData?.date_to ? moment(filtersData.date_from).format('YYYY-MM-DD') : null,
                    date_to: filtersData.date_to ? moment(filtersData.date_to).format('YYYY-MM-DD') : null,
                    page: page > 1 ? page : null,
                    per_page: isFiltersOpen ? 12 : 16,
                }
            })
                .then((res) => {
                    dispatch(getListingsReceived(res.data))
                })
                .catch(() => {
                    dispatch(getListingsFailed())
                })
        }
    }, [page, isFiltersOpen, filtersData, category]);

    return (
        <main className={clsx(styles.wrapper, 'wrapper_new_pagination')}>
            <section className={styles.header}>
                <div className={styles.contentBg}/>
                <div className={styles.headerInner}>
                    <h1 className={styles.title}>Find a place for your art</h1>
                    <div className={styles.headerTabs}>
                        <MainButton
                            svg={<ListIcon/>}
                            text="Show list"
                            className={clsx(styles.headerTabButton, tab === 0 && styles.headerTabButtonActive)}
                            onClick={() => setTab(0)}
                        />
                        <MainButton
                            svg={<MapIcon/>}
                            text="Show map"
                            className={clsx(styles.headerTabButton, tab === 1 && styles.headerTabButtonActive)}
                            onClick={() => setTab(1)}
                        />
                    </div>
                </div>
                <div className={styles.tabsWrapper}>
                    <StyledTabs centered defaultActiveKey={category} items={items}/>
                </div>
            </section>
            <section className={clsx(styles.content, tab === 1 && styles.contentWithMap)}>
                <div className={styles.topFilters}>
                    {innerWidth > 992 ? (
                        <StyledButton
                            color="black"
                            className={styles.filterButton}
                            onClick={toggleIsFiltersOpen}
                        >
                            {isFiltersOpen ? <ChevronLeft /> : <FilterIcon />}
                            Filters
                        </StyledButton>
                    ) : (
                        <IconButton
                            size="large"
                            className={clsx(styles.filterButton, isFiltersOpen && styles.filterButtonOpen)}
                            icon={Filter2Icon}
                            onClick={toggleIsFiltersOpen}
                        />
                    )}
                    <div>
                        <AutocompleteTextInput
                            value={address}
                            placeholder="Locations"
                            onSelect={handleSelectAddress}
                            onChange={handleChangeAddress}
                            className={styles.input}
                        />
                    </div>
                    {innerWidth > 992 && (
                        <>
                            <div>
                                <StyledDatePicker
                                    selectsRange
                                    monthsShown={2}
                                    placeholderText="Dates"
                                    selectsDisabledDaysInRange
                                    selected={filtersData.date_from}
                                    startDate={filtersData.date_from}
                                    endDate={filtersData.date_to}
                                    onChange={handleChangeDate}
                                />
                            </div>
                            <div>
                                <IncrementDecrementInput
                                    content={`${filtersData.guests_num || 1} guest`}
                                    value={filtersData.guests_num || 1}
                                    onChange={handleChangeGuests}
                                />
                            </div>
                        </>
                    )}
                </div>
                <div className={clsx(styles.contentInner, isFiltersOpen && styles.contentInnerWithFilters)}>
                    {isFiltersOpen && (
                        <>
                            <ExperiencesFilters
                                filters={filtersData}
                                onChangeFilters={handleChangeFilters}
                                onClear={handleClearFilters}
                            />
                            <div className={styles.divider}/>
                        </>
                    )}
                    <div className={styles.inner}>
                        {tab === 0 && (
                            loading ? (
                                <Loader/>
                            ) : (
                                listings.length > 0 ? (
                                    <>
                                        <div className={styles.experiencesList}>
                                            {listings.map((item) => (
                                                <ExperiencesStayItem
                                                    key={item.id}
                                                    {...item}
                                                    category={category}
                                                />
                                            ))}
                                        </div>
                                        <div className={styles.pagination}>
                                            <NewPaginationView
                                                activePage={{
                                                    page,
                                                    pages,
                                                }}
                                                onChange={handleChangePage}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <Row justify="center" gutter={[15, 15]} className={styles.noDataBlock}>
                                        <Col span={14}>
                                            <Typography>
                                                No listings found. Have a place to rent?
                                            </Typography>
                                        </Col>
                                        <Col span={14}>
                                            <StyledButton onClick={handleAddListing}>
                                                Add yours!
                                            </StyledButton>
                                        </Col>
                                    </Row>
                                )
                            )
                        )}
                        {tab === 1 && (
                            <ExperiencesMap list={listings} isFilterOpen={isFiltersOpen} />
                        )}
                    </div>
                </div>
            </section>
        </main>
    )
}
