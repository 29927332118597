import React, {useState} from "react";
import './styles/mobile_menu.css'
import OutsideClickHandler from "../Constants/detectOutsideClick";
import {bodyOverflow} from "../Constants/bodyOverflow";
import artist_avatar_default from "../assets/img/new_landing/profile_avatar_default.svg";
import investor_avatar_default from "../assets/img/new_landing/investor_avatar_default.svg";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setActiveUpload} from "../main-store/auth/actions";
import {userLogout} from "../api/API";

export default function MobileMenu(props) {

    const {
        activeBurger, navigationLinksLayout, loginRegisterButtons,
        setActiveBurger, mobile, user_data, showBanner
    } = props;

    const uploadDispatch = useDispatch();

    const [activeMobilePopup, setActiveMobilePopup] = useState(false)
    return (
        <OutsideClickHandler callback={() => {
            bodyOverflow(false)
            setActiveBurger(false)
        }}>
            <div style={showBanner ? {top: '64px'} : {}} className={`mobile_menu_wrapper ${activeBurger ? 'mobile_menu_wrapper_active' : ''}`}>
                {user_data?.role
                    ? <div className={'signed_user_wrapper'}>
                        <OutsideClickHandler callback={() => setActiveMobilePopup(false)}>
                            <div onClick={() => setActiveMobilePopup(state => !state)}
                                 className={'user_content_wrapper'}>
                                <div className={'avatar_wrapper'}>
                                    <img src={user_data?.avatar ? user_data?.avatar
                                        : user_data?.role === 'artist'
                                            ? artist_avatar_default : investor_avatar_default}
                                         alt="avatar"/>
                                    <span>{user_data?.name}</span>
                                </div>
                                {user_data.role === 'artist'
                                    ? <Link className={'upload'} onClick={() => {
                                        uploadDispatch(setActiveUpload(true))
                                        setActiveMobilePopup(false)
                                        setActiveBurger(false)
                                        bodyOverflow(false)
                                    }}
                                            to={'/profile/portfolio'}>Upload</Link>
                                    : <Link className={'cart'} to={'/cart'}>
                                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.86914 10.3333H27.2558C28.0009 10.3333 29.5649 10.3333 30.2094 10.3333C30.2094 10.3333 29.9833 12.5238 29.9091 13.2653L29.1091 21.2653C29.0433 21.9233 28.7817 23.6666 28.7817 23.6666C28.2949 23.6666 27.1171 23.6666 26.4558 23.6666H13.7225C13.1058 23.6668 11.5 23.6666 11.5 23.6666C10.71 19.2067 9.75714 14.7743 8.86914 10.3333Z" stroke="black" strokeWidth="1.5"/>
                                            <path d="M8.86914 10.3333L7.78914 6.00933C7.71691 5.721 7.5 5.00002 7.5 5.00002C7.5 5.00002 6.79305 5.00001 6.49581 5H4.86914" stroke="black" strokeWidth="1.5" strokeLinecap="square"/>
                                            <path d="M12.8691 29H15.5358" stroke="black" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
                                            <path d="M23.5352 29H26.2018" stroke="black" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
                                        </svg>
                                    </Link>}
                            </div>
                            <div className={`signed_user_content ${activeMobilePopup
                                ? 'signed_user_content_active' : ''}`}>
                                <Link onClick={() => {
                                    setActiveMobilePopup(false)
                                    setActiveBurger(false)
                                    bodyOverflow(false)
                                }}
                                      to={user_data?.role === 'investor' ? '/profile/about' : '/profile/portfolio'}>{user_data?.role === 'investor' ? 'Profile' : 'Portfolio'}</Link>
                                <Link onClick={() => {
                                    setActiveMobilePopup(false)
                                    setActiveBurger(false)
                                    bodyOverflow(false)
                                }}
                                      to={'/profile/settings'}>Settings</Link>
                                <Link onClick={() => {
                                    setActiveMobilePopup(false)
                                    setActiveBurger(false)
                                    bodyOverflow(false)
                                }}
                                      to={'/profile/support'}>Support</Link>
                                <div className={'user_logout'} onClick={() => {
                                    userLogout(true)
                                    setActiveBurger(false)
                                    bodyOverflow(false)
                                }}>Sign Out
                                </div>
                            </div>
                        </OutsideClickHandler>
                    </div> : null}
                {navigationLinksLayout(mobile)}
                <div className={'login_buttons'}>
                    {loginRegisterButtons(mobile)}
                </div>
                {/*<a onClick={() => setActiveBurger(false)}*/}
                {/*   className={'artsted_digital_link'}*/}
                {/*   href={'/'}>Artsted Digital></a>*/}
            </div>
        </OutsideClickHandler>
    )
}
