import {Typography} from "antd";
import {useState} from "react";

import StyledButton from "../../../Components/ui/StyledButton";
import ListingForm from "../../../Components/profile/ListingForm";

import styles from './ProfileCreateListing.module.css'

export default function ProfileCreateListing() {
    const [activeTab, setActiveTab] = useState('stays');

    const handleChangeTab = (tab) => () => setActiveTab(tab);

    return (
        <div className={styles.root}>
            <div className={styles.row}>
                <div>
                    <Typography.Title level={4}>Add new listing</Typography.Title>
                    <div className={styles.subtitle}>Choose which type of listing you want to add</div>
                </div>
                <div className={styles.tabs}>
                    {['studios', 'events', 'stays'].map((tab) => (
                        <StyledButton
                            key={tab}
                            className={styles.tabButton}
                            color={activeTab === tab ? 'black' : 'white'}
                            onClick={handleChangeTab(tab)}
                        >
                            {tab.replace(/^\w/, l => l.toUpperCase())}
                        </StyledButton>
                    ))}
                </div>
            </div>
            <ListingForm />
        </div>
    )
}