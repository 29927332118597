import {Col, Row, Space, Typography} from "antd";
import clsx from "clsx";
import moment from "moment";
import {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useHistory} from "react-router-dom";

import API from "../../../api/API";

import CheckInOutDates from "../../../Components/ui/CheckInOutDates";
import StyledDatePicker from "../../../Components/ui/StyledDatePicker";
import IncrementDecrementInput from "../../../Components/ui/IncrementDecrementInput";
import StyledButton from "../../../Components/ui/StyledButton";
import StayBaseInfo from "../../../Components/ui/StayBaseInfo";

import styles from './ProfileBookingSettings.module.css';

export default function ProfileBookingSettings() {
    const history = useHistory();
    const {id, type} = useParams();
    const [data, setData] = useState();
    const [newPrice, setNewPrice] = useState({});
    const [guests, setGuests] = useState(0);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isSending, setIsSending] = useState(false);
    const [error, setError] = useState('');
    const dur = moment(data?.date_to).diff(moment(data?.date_from), 'days');

    useEffect(() => {
        API().get(`/user/listing/book?id=${id}`)
            .then((res) => {
                setData(res.data);
                setGuests(res.data?.guests_num);
            })
    }, [id]);

    const handleChangeDate = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (start && end) {
            API().get('/user/listing/book/dates', {
                params: {
                    id,
                    date_from: moment(start).format('YYYY-MM-DD'),
                    date_to: moment(end).format('YYYY-MM-DD')
                }
            })
                .then((res) => {
                    setError('');
                    setNewPrice(res.data);
                })
                .catch((e) => {
                    setError(e?.response?.data?.message);
                })
        }
    };

    const handleConfirmNewGuests = () => {
        if (guests !== data.guests_num) {
            setIsSending(true);
            API().post('/user/listing/book/guests', {
                id,
                guests_num: guests
            })
                .then(() => history.push('/profile/artist-bnb'))
                .finally(() => setIsSending(false))
        }
    }

    const handleConfirmNewDates = () => {
        if (startDate && endDate) {
            setIsSending(true);
            API().post('/user/listing/book/dates', {
                id,
                date_from: moment(startDate).format('YYYY-MM-DD'),
                date_to: moment(endDate).format('YYYY-MM-DD')
            })
                .then(() => history.push('/profile/artist-bnb'))
                .finally(() => setIsSending(false))
        }
    }

    return (
        <div>
            <div className={styles.header}>
                <h1 className={styles.title}>
                    {type === 'dates' && 'Change dates'}
                    {type === 'guests' && 'Manage guests'}
                </h1>
            </div>
            <div className={styles.body}>
                <div className={styles.left}>
                    <StayBaseInfo
                        address={data?.listing_address}
                        image={data?.listing_photos?.[0]?.path}
                        name={data?.listing_name}
                    />
                    <div className={styles.formWrapper}>
                        {type === 'dates' && (
                            <>
                                <div className={styles.inner}>
                                    <Typography.Title level={4}>
                                        Current Dates
                                    </Typography.Title>
                                    <CheckInOutDates
                                        checkIn={data?.date_from}
                                        checkOut={data?.date_to}
                                        checkInFromTime={data?.listing_check_in_from_time}
                                        checkInToTime={data?.listing_check_in_to_time}
                                        checkOutFromTime={data?.listing_check_out_from_time}
                                        checkOutToTime={data?.listing_check_out_to_time}
                                    />
                                </div>
                                <Space direction="vertical" size={16}>
                                    <div>
                                        <Typography.Title level={4}>
                                            New Dates
                                        </Typography.Title>
                                        <Typography.Text>
                                            Select new dates in the calendar below
                                        </Typography.Text>
                                    </div>
                                    <CheckInOutDates
                                        checkIn={startDate}
                                        checkOut={endDate}
                                        checkInFromTime={data?.listing_check_in_from_time}
                                        checkInToTime={data?.listing_check_in_to_time}
                                        checkOutFromTime={data?.listing_check_out_from_time}
                                        checkOutToTime={data?.listing_check_out_to_time}
                                    />
                                </Space>
                                <div className={styles.calendarContainer}>
                                    <StyledDatePicker
                                        inline
                                        selectsRange
                                        monthsShown={2}
                                        selected={startDate}
                                        startDate={startDate}
                                        endDate={endDate}
                                        selectsDisabledDaysInRange
                                        onChange={handleChangeDate}
                                    />
                                </div>
                                <div>
                                    {error && <Typography.Text type="danger">{error}</Typography.Text>}
                                </div>
                            </>
                        )}
                        {type === 'guests' && (
                            <>
                                <div className={styles.inner}>
                                    <Typography.Title level={4}>Current Guests</Typography.Title>
                                    {data?.guests_num}
                                </div>
                                <div className={styles.guestForm}>
                                    <Typography.Title level={4}>New Guest Number</Typography.Title>
                                    <Typography.Text>Enter a new number of guests</Typography.Text>
                                </div>
                                <Row>
                                    <Col xs={24} sm={12} lg={10}>
                                        <IncrementDecrementInput
                                            content={`${guests} guest`}
                                            value={guests}
                                            onChange={setGuests}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={24} sm={12} lg={10}>
                                        <StyledButton
                                            block
                                            loading={isSending}
                                            className={styles.confirmButton}
                                            onClick={handleConfirmNewGuests}
                                        >
                                            Confirm
                                        </StyledButton>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </div>
                </div>
                {type === 'dates' && (
                    <div className={styles.aside}>
                        <Typography.Title level={4} className={styles.asideTitle}>Summary</Typography.Title>
                        <div className={styles.asideRow}>
                            <span>Price per night:</span>
                            <span>€{data?.price/dur}</span>
                        </div>
                        <div className={styles.asideRow}>
                            <span>Current price:</span>
                            <span>€{data?.price}</span>
                        </div>
                        <div className={styles.asideRow}>
                            <span>New price:</span>
                            <span>€{newPrice?.new_price}</span>
                        </div>
                        <div className={styles.asideRow}>
                            <span>Discount:</span>
                            <span>-</span>
                        </div>
                        <div className={clsx(styles.asideRow, styles.asideRowTotal)}>
                            <span>Total new price:</span>
                            <span>€{newPrice.new_price ?? '-'}</span>
                        </div>
                        <div className={styles.asideDescription}>
                            {(newPrice.new_price && newPrice.new_price > newPrice.old_price) && (
                                <>
                                    Your new price is higher than the current price, so we will only charge you the difference <b>€{newPrice.new_price - newPrice.old_price}</b>
                                </>
                            )}
                        </div>
                        <StyledButton loading={isSending} disabled={!startDate || !endDate || error} block onClick={handleConfirmNewDates}>
                            Confirm
                        </StyledButton>
                    </div>
                )}
            </div>
        </div>
    )
}