//eslint-disable-next-line
import React, {useEffect} from 'react';
import {useHistory} from "react-router";
import axios from "axios";
import {setCurrencies, setCurrency} from "../main-store/auth/actions";
import {connect} from "react-redux";
import {currencies} from "../Constants/currencies";

const GetCurrencies = ({children, currency, setCurrency, setCurrencies}) => {
    const history = useHistory()

    const fetchCurrencies = () => {
        axios.get("http://ip-api.com/json/?fields=currency")
            .then(res => {
                const newCurrency = res.data.currency
                if (currencies.find(i => i.value === newCurrency) && currency !== newCurrency) {
                    setCurrency(newCurrency)
                }
            })
        axios.get("https://api.artsted.com/currencies")
            .then(res => {
                setCurrencies(res.data.currencies)
            })
    }

    useEffect(() => {
        history.listen(fetchCurrencies)
        fetchCurrencies()
        //eslint-disable-next-line
    }, [history])

    return children
};


const mapStateToProps = state => ({
    currencies: state.auth.currencies,
    currency: state.auth.currency
})

const putDispatchToProps = {
    setCurrencies,
    setCurrency
}

export default connect(mapStateToProps, putDispatchToProps)(GetCurrencies);