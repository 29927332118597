import React, {useEffect, useState} from 'react';
import API from '../../api/API';
import classes from './styles/login.module.scss';
import Wrapper from './OnboardingWrapper';
//import artwork_img from '../../assets/img/onboarding/artwork2.png'
import Input from '../../Components/CustomComponents/Onboarding/Input';
import Button from '../../Components/CustomComponents/Onboarding/Button';
import {useHistory} from 'react-router-dom';
import Keys from '../../Constants/helper';
import {setUserData, setUserRegistrationData} from '../../main-store/auth/actions';
import {clearCookies} from '../../Constants/clearCookies';
import {useDispatch} from 'react-redux';
import regExp from '../../Constants/regExp';
import Error from '../../Components/CustomComponents/Onboarding/Error';
import Popup from "../../Components/Popup";
import {Loader} from "../../Components/Loader";


function FacebookEmailLogin() {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({});
    const [showErrors, setVisibilityErrors] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const [artImg, setArtImg] = useState(null);
    const [userBlockedPopup, setUserBlockedPopup] = useState({show: null, reason: null});

    console.log('email: ', email)
    console.log('errors: ', errors)

    useEffect(() => {
        API().get(`/admin/landing`)
            .then(res => {
                if (res.data) {
                    setArtImg(res.data.artworks_top[Math.floor(Math.random() * res.data.artworks_top.length)])
                    setLoading(true)
                }
            })
    }, [])

    // useEffect(() => {
    //     dispatch(setUserRegistrationData(null))
    //     // eslint-disable-next-line
    // }, []);

    useEffect(() => {
        if (showErrors) {
            _isFormValid();
        }
        // eslint-disable-next-line
    }, [email]);

    const _isFormValid = () => {
        const errors = {};

        if (!showErrors) {
            setErrors({});
        }

        if (!email.trim()) {
            errors.email = "Should not be empty";
        } else if (!regExp.email.test(email)) {
            errors.email = "Enter valid email"
        }

        if (Object.keys(errors).length) {
            setErrors(errors);
            return false;
        } else {
            setErrors({});
        }

        return true;
    }

    // const _onSubmit = (e) => {
    //     e.preventDefault();
    //     console.log("e: ", e)
    //     API().post(`/user/registration/end_oauth`, {"email": email})
    //         .then(res => {
    //             console.log(res)
    //         })
    // }

    const _onSubmit = e => {
        e.preventDefault();

        if (!_isFormValid()) {
            setVisibilityErrors(true);
            return;
        }

        API().post(`/user/registration/end_oauth`, {"email": email})
            .then(res => {
                // console.log(res);
                if (res.data.error === "user blocked") {
                    setUserBlockedPopup({reason: res.data.reason, show: true})
                } else if (res.data.registration_end) {
                    clearCookies();
                    localStorage.setItem(Keys.JWT_TOKEN, res.data.access_token)
                    dispatch(setUserData({
                        name: res.data.name,
                        surname: res.data.surname,
                        role: res.data.role,
                        avatar: res.data.avatar ? res.data.avatar : null
                    }));
                    window.location.pathname = res.data.role === 'investor' ? '/profile/about' : '/profile/portfolio';
                } else {
                    localStorage.setItem(Keys.JWT_TOKEN_REGISTRATION, res.data.access_token)

                    dispatch(setUserRegistrationData({
                        name: res.data.name,
                        surname: res.data.surname,
                        type: res.data.role
                    }))
                    history.push(res.data.role === 'artist' ? '/registration/work' : '/registration/greeting');


                    let bucket = localStorage.getItem('artstedBucketList') ? JSON.parse(localStorage.getItem('artstedBucketList')) : []
                    bucket.map(art => (
                        API().post(`/user/basket`, {id: art.artwork.id})
                            .catch(err => {
                                console.log(err);
                            })
                    ))

                    localStorage.setItem('artstedBucketList', JSON.stringify([]))
                }
            })
            .catch(e => {
                const error = e.response?.data?.error;
                if (error !== 'Wrong password') {
                    setErrors({email: error});
                }
            })
    }

    return (
        <Wrapper>
            <Wrapper.Form>
                <div className={classes.login}>
                    <h1 className={classes.title}>Fill in Your email address</h1>
                    <form className={classes.form} onSubmit={_onSubmit}>
                        <Input
                            className={classes.input}
                            value={email}
                            onChange={({target}) => setEmail(target.value.trim())}
                            title="E-Mail"
                            error={errors.email}
                            type="text"
                            autoComplete="off"
                        />
                        <div className={classes.errors}>
                            {Object.keys(errors).map(key => {
                                return <Error text={errors[key]} key={key}/>
                            })}
                        </div>
                        <div className={classes.form_footer}>
                            <div className={classes.footer_btns}>
                                <Button type="submit" className={classes.submit}>Continue &#62;</Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Wrapper.Form>
            {artImg && loading ?
                <Wrapper.Picture src={artImg.photo} imgRight artAuthor={artImg.user.name}
                                 authorAvatar={artImg.user.avatar}/>
                : <Loader color={{
                    '--bgColor': '#000',
                    '--size': '48px',
                    '--wrapper_size': '40px'
                }}/>}
            {userBlockedPopup.show ?
                <Popup closeCallback={() => setUserBlockedPopup({show: false})}
                       key={'2'}
                       title={'User is blocked'}
                       to_top={true}
                       description={userBlockedPopup.reason}/>
                : null}
        </Wrapper>
    );
}

export default FacebookEmailLogin;