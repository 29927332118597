import {useState} from "react";

import StyledTabs from "../../../Components/ui/StyledTabs";
import ProfileListings from "../ProfileListings";
import ProfileBookings from "../ProfileBookings";

import styles from './ProfileArtistBnB.module.css';

export default function ProfileArtistBnB() {
    const [tab, setTab] = useState('listings');

    return (
        <div className={styles.wrapper}>
            <StyledTabs
                centered
                className={styles.tabs}
                defaultActiveKey={tab}
                items={[
                    {key: 'listings', label: 'My Listing'},
                    {key: 'bookings', label: 'My Bookings'}
                ]}
                onChange={setTab}
            />
            {tab === 'listings' && <ProfileListings />}
            {tab === 'bookings' && <ProfileBookings />}
        </div>
    )
}