export default class Keys {
    static JWT_TOKEN = "ArtstedJwtToken";
    static JWT_TOKEN_REGISTRATION = "ArtstedJwtTokenRegistration";
    static REGISTRATION_DATA = "ArtstedRegData";
    static CURRENCY = "ArtstedCurrency";
    static UNITS = "ArtstedUnits";
    static USER_DATA = "ArtstedData";
    static JWT_TOKEN_ADMIN = "ArtstedAdminJwtToken";
    static RECENT_VIEW = "ArtstedUserRecentList";
    static VERIFIED_POPUP = "ArtstedVerifiedPopup";
    static LANDING_ROLE = "ArtstedRole";
    static SUBSCRIPTION_DATA = "subscriptionData";
    static FOOTER_CATEGORY = "footerCategory";
    static SUBSCRIPTION_PLAN_BILL = "subscriptionPlanBill";
    static RESET_PASSWORD_CODE = "resetPasswordCode";
    static TOP_BANNER_HIDE = "topBannerHide";
    static CAN_CREATE = 'canCreate';
    static FROM_APP = "fromApp";
    static APP_PLATFORM = 'appPlatform'
}
