import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import API from "../../../api/API";
import {getMyListingsFailed, getMyListingsReceived, getMyListingsRequest} from "../../../main-store/listing/actions";

import ListingItem from "../../../Components/profile/ListingItem";
import StyledButton from "../../../Components/ui/StyledButton";
import {Loader} from "../../../Components/Loader";

import styles from './ProfileListing.module.css'

export default function ProfileListings() {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState('stays');
    const {myListings: {items, loading}} = useSelector(state => state.listings);

    const handleChangeTab = (tab) => () => setActiveTab(tab);

    useEffect(() => {
        dispatch(getMyListingsRequest())
        API().get('/listing/list', {
            params: {
                type: 'Stay',
                my_listings_only: true
            }
        })
            .then((res) => {
                dispatch(getMyListingsReceived(res.data))
            })
            .catch(() => {
                dispatch(getMyListingsFailed())
            })
    }, [])

    return (
        <main className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.tabs}>
                    {['studios', 'events', 'stays'].map((tab) => (
                        <StyledButton
                            key={tab}
                            className={styles.tabButton}
                            color={activeTab === tab ? 'black' : 'white'}
                            onClick={handleChangeTab(tab)}
                        >
                            {tab.replace(/^\w/, l => l.toUpperCase())}
                        </StyledButton>
                    ))}
                </div>
                <Link to="/profile/listings/new">
                    <StyledButton block color="transparent">
                        Add New Listing
                    </StyledButton>
                </Link>
            </div>
            {loading ? (
                <Loader/>
            ) : (
                <div className={styles.list}>
                    {items.map((listing) => (
                        <ListingItem key={listing.id} {...listing} />
                    ))}
                </div>
            )}
        </main>
    )
}