import React, {useEffect, useMemo, useState} from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import useResize from '../../Constants/useResize';
import mastercard_icon from '../../assets/img/mastercard_icon.svg';
import plan_top from '../../assets/img/plan_active_top.svg';
import plan_bottom from '../../assets/img/plan_active_bottom.svg';
import useBottomPopup from '../../Constants/useBottomPopup';
import API, {forceLogout} from '../../api/API';
import '../Profile/styles/new_settings.css';
import MainButton from '../../Components/CustomComponents/MainButton';
import StripeFields from '../../Components/ProfileComponents/StripeFields';
import { useDispatch, useSelector } from 'react-redux';
import ChangedConfirmedPopup from '../../Components/CustomComponents/ChangedConfirmedPopup';
import TextInput from '../../Components/CustomComponents/TextInput';
import { Loader } from '../../Components/Loader';
import { Link, useLocation } from 'react-router-dom';
import CustomCheckbox from '../../Components/CustomComponents/CheckBox';
import ModalWindow from '../../Components/ModalWindow';
import { bodyOverflow } from '../../Constants/bodyOverflow';
import CustomDropdown from '../../Components/CustomComponents/Dropdown';
import { ResizeTextBox } from '../../Components/ResizeTextBox';
import { setUserCategories, setUserData } from '../../main-store/auth/actions';
import Keys from "../../Constants/helper";

const stripeState = {
  phone: {
    value: '',
    error: false,
    errorMessage: null,
  },
  city: {
    value: '',
    error: false,
    errorMessage: null,
  },
  country: {
    value: '',
    error: false,
    errorMessage: null,
    options: [],
    popup: false,
  },
  zip: {
    value: '',
    error: false,
    errorMessage: null,
  },
  state: {
    value: '',
    error: false,
    errorMessage: null,
  },
  street: {
    value: '',
    error: false,
    errorMessage: null,
  },
  loading: false,
  error: null,
};

const personalInfoState = {
  loading: false,
  name: {
    value: '',
    error: false,
    errorMessage: 'Fill this label',
    required: true,
  },
  surname: {
    value: '',
    error: false,
    errorMessage: 'Fill this label',
    required: true,
  },
  email: {
    value: "",
  },
  country: {
    value: '',
    error: false,
    errorMessage: null,
    options: [],
    popup: false,
    required: true,
  },
  categories: {
    value: '',
    popup: false,
    dataArray: [],
    required: true,
    error: false,
    errorMessage: 'You have to choose at least one option',
    options: [],
  },
  linkedin_link: {
    value: '',
  },
  facebook_link: {
    value: '',
  },
  instagram_link: {
    value: '',
  },
  about_yourself: {
    value: '',
    required: true,
    error: false,
    errorMessage: 'Fill this label',
  },
  seo_text: {
    value: '',
    required: true,
    error: false,
    errorMessage: 'Fill this label',
  },
  ethereum_address: {
    value: '',
    errorMessage: 'Fill this label',
    required: false,
    error: null,
  },
};

const membershipIncludesArtist = [
  'See basic statistics',
  'Verify profile',
  'Receive notifications and alerts',
  'Upload artwork',
  'See advanced analytics and price charts',
  'Get personalised support',
  'Premium promotion tools',
  'Access the Non-fungible tokens creation tools',
  'Receive exhibition opportunities',
  'A dedicated curator',
  'Community mentorship program',
];

const membershipIncludes = [
  'See basic statistics',
  'Verify profile',
  'Receive notifications and alerts',
  'See advanced analytics and price charts',
  'Get personalised support',
  'Premium shipping and handing',
];

export default function NewSettings() {
  const stripe = useStripe();
  const { innerWidth } = useResize();
  const initialState = { value: '', error: null };
  const [subscriptionPlan, setSubscriptionPlan] = useState({
    subscription: null,
    loading: false,
  });
  const [currentPassword, setCurrentPassword] = useState(initialState);
  const [newPassword, setNewPassword] = useState(initialState);
  const [repeatPassword, setRepeatPassword] = useState(initialState);
  const [wrongPassword, setWrongPassword] = useState(null);
  const [checkboxes, setCheckboxes] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [subscriptionInfo, setSubscriptionInfo] = useState({});
  const [personalInfo, setPersonalInfo] = useState(() => personalInfoState);
  // const [ethereumAddress, setEthereumAddress] = useState('')
  const [userInfo, setUserInfo] = useState({});
  const [activeModal, setActiveModal] = useState({ value: false, show: true });
  const [stripeFields, setStripeFields] = useState(stripeState);
  const [deletePayoutPopup, setDeletePayoutPopup] = useState(false);
  const [canCreateRef, setCanCreateRef] = useState(false);
  let { successText, changingConfirmed, changeContinuePopup } =
    useBottomPopup();
  let dispatch = useDispatch();
  let store = useSelector((store) => store.auth.user_data);
  let location = useLocation();

  console.log(canCreateRef);

  useEffect(() => {
    API()
      .get(`/user/payment/subscription`)
      .then((res) => {
        console.log(res);
        if (res.data.subscription) {
          setSubscriptionPlan({
            subscription: res.data.subscription,
            cancel_at_period_end: res.data.cancel_at_period_end,
            loading: true,
          });
        }
      });
  }, []);

  const deletePayout = () => {
    API()
      .delete(`/user/payment/registration`)
      .then((res) => {
        // console.log(res)
        if (profileData) {
          setProfileData((prevState) => ({
            ...prevState,
            bank_last4: null,
            bank_routing_number: null,
            // card: true,
            charges_enabled: false,
          }));
          setDeletePayoutPopup(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    Promise.all([
      API().get(`/user/payment/shipping_address`),
      API().get(`/user/countries`),
      API().get(`/user/profile/settings`),
      API().get(`/user/profile`),
      API().get(`/user/categories`),
      API().get(`/user/profile/info`),
      // API().get(`/user/ethereum_address`)
    ])
      .then((res) => {
        console.log('resresresresres: ', res);
        setStripeFields((prevState) => ({
          ...prevState,
          phone: {
            ...prevState.phone,
            value: res[0].data.phone ?? '',
          },
          country: {
            ...prevState.country,
            value: res[0].data.country ?? '',
            options: [...res[1].data.countries],
          },
          city: {
            ...prevState.city,
            value: res[0].data.city ?? '',
          },
          zip: {
            ...prevState.zip,
            value: res[0].data.zip ?? '',
          },
          state: {
            ...prevState.state,
            value: res[0].data.state ?? '',
          },
          street: {
            ...prevState.street,
            value: res[0].data.street ?? '',
          },
          loading: true,
        }));

        setPersonalInfo((prevState) => ({
          ...prevState,
          ...res[1].data,
          loading: true,
          name: {
            ...prevState.name,
            value: res[5].data.name ?? '',
          },
          surname: {
            ...prevState.surname,
            value: res[5].data.surname ?? '',
          },
          country: {
            ...prevState.country,
            value: res[5].data.country ?? '',
            options: [...res[1].data.countries],
          },
          categories: {
            ...prevState.categories,
            dataArray: res[5].data.categories ?? [],
            options: res[4].data.categories ?? [],
          },
          linkedin_link: {
            value: res[5].data.linkedin_link ?? '',
          },
          facebook_link: {
            value: res[5].data.facebook_link ?? '',
          },
          instagram_link: {
            value: res[5].data.instagram_link ?? '',
          },
          about_yourself: {
            ...prevState.about_yourself,
            value: res[5].data.about_yourself ?? '',
          },
          seo_text: {
            ...prevState.seo_text,
            value: res[5].data.seo_text ?? '',
          },
          ethereum_address: {
            ...prevState.ethereum_address,
            value: res[5].data.ethereum_address ?? '',
          },
          email: {
            value: res[5].data.email
          }
        }));

        setCheckboxes(res[2].data);
        console.log(res[3].data);
        setProfileData(res[3].data);
      })
      .then(() => setLoading(true));
  }, [store?.role]);

  useEffect(() => {
    let urlNav = location?.hash?.split('#')[1];
    if (urlNav && loading && store?.role === 'artist') {
      let element = document.getElementById(urlNav);
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [location.hash, loading, store?.role]);

  let stripeRedirectRecipient = () => {
    API()
      .post(`/user/payment/registration`)
      .then(({ data }) => {
        window.location.href = data.url;
      });
  };

  let stripeRedirectPayers = () => {
    API()
      .get(`/user/payment/change_card`)
      .then(({ data }) => {
        stripe.redirectToCheckout({ sessionId: data.session_id });
      })
      .catch((e) => console.log(e));
  };

  const stripeSubmit = (event) => {
    event.preventDefault();
    const { country, country_code, phone, state, street, zip, city } =
      stripeFields;

    let condition =
      country.value?.trim() &&
      city.value?.trim() &&
      phone.value?.trim() &&
      street.value?.trim() &&
      zip.value?.trim();
    if (condition) {
      let regData = {
        country: country.value,
        phone: phone.value,
        state: state.value,
        street: street.value,
        city: city.value,
        zip: zip.value,
        country_code,
      };
      API()
        .post(`/user/payment/shipping_address`, regData)
        .then(() => {
          changeContinuePopup();
          setTimeout(() => changeContinuePopup(), 2000);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 400 && err.response.data.message) {
            setStripeFields((prevState) => ({
              ...prevState,
              error: err.response.data.message,
            }));
          } else {
            //todo
            setStripeFields((prevState) => ({
              ...prevState,
              error: 'Data validation failed',
            }));
          }
        });
    } else {
      Object.entries(stripeFields).forEach((item) => {
        if (item[1] instanceof Object) {
          if (!item[1]?.value) {
            setStripeFields((prevState) => ({
              ...prevState,
              [item[0]]: {
                ...prevState[item[0]],
                error: true,
                errorMessage: 'Please fill this label',
              },
            }));
          }
        }
      });
    }
  };

  const StripeCard = ({ condition, onClick, text, buttonPremium }) => (
    <div className={'stripe_button_wrapper'}>
      <MainButton
        className={buttonPremium ? 'button_white' : 'button_black'}
        style={
          buttonPremium
            ? { margin: '0 auto 4rem' }
            : condition
            ? {
                background: '#000',
                color: '#fff',
              }
            : null
        }
        onClick={onClick}
        text={text}
      />
    </div>
  );

  const paymentMethod = (profile_data) => (
    <div className={'bank_cards'}>
      {profile_data?.card_brand && profile_data?.card_last4 ? (
        <div className={'gap'}>
          <h6>Added card:</h6>
          <div className={'card_placeholder'}>
            <div className={'numbers'}>
              {profile_data.card_brand === 'visa' ? (
                <svg
                  width='22'
                  height='21'
                  viewBox='0 0 22 21'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M17.9552 9.53516C17.9552 9.53516 18.2455 10.9883 18.3104 11.293H17.0347C17.1608 10.9453 17.6458 9.59375 17.6458 9.59375C17.6382 9.60547 17.7719 9.23828 17.8483 9.01172L17.9552 9.53516ZM22 3.625V17.375C22 18.4102 21.1788 19.25 20.1667 19.25H1.83333C0.821181 19.25 0 18.4102 0 17.375V3.625C0 2.58984 0.821181 1.75 1.83333 1.75H20.1667C21.1788 1.75 22 2.58984 22 3.625ZM5.82465 13.4375L8.23854 7.375H6.61528L5.11424 11.5156L4.95 10.6758L4.41528 7.88672C4.32743 7.5 4.05625 7.39062 3.72014 7.375H1.24896L1.22222 7.49609C1.82569 7.65234 2.36424 7.87891 2.83403 8.16406L4.20139 13.4375H5.82465ZM9.43021 13.4453L10.3927 7.375H8.85729L7.89861 13.4453H9.43021ZM14.7736 11.4609C14.7812 10.7695 14.3687 10.2422 13.4865 9.80859C12.9479 9.53125 12.6194 9.34375 12.6194 9.05859C12.6271 8.80078 12.8983 8.53516 13.5017 8.53516C14.0021 8.52344 14.3687 8.64453 14.6437 8.76562L14.7812 8.83203L14.9913 7.51953C14.6896 7.39844 14.2083 7.26172 13.6163 7.26172C12.1 7.26172 11.0344 8.08984 11.0267 9.26953C11.0153 10.1406 11.7906 10.625 12.3712 10.918C12.9632 11.2148 13.1656 11.4102 13.1656 11.6719C13.158 12.0781 12.6844 12.2656 12.2451 12.2656C11.634 12.2656 11.3056 12.168 10.8052 11.9414L10.6028 11.8438L10.3889 13.207C10.7479 13.375 11.4125 13.5234 12.1 13.5312C13.7118 13.5352 14.7622 12.7188 14.7736 11.4609ZM20.1667 13.4453L18.9292 7.375H17.7413C17.3747 7.375 17.0958 7.48437 16.9392 7.87891L14.659 13.4453H16.2708C16.2708 13.4453 16.5344 12.6953 16.5917 12.5352H18.5625C18.6083 12.75 18.7458 13.4453 18.7458 13.4453H20.1667Z'
                    fill='black'
                  />
                </svg>
              ) : (
                <img src={mastercard_icon} alt='mastercard_icon' />
              )}
              <span>•••• {profile_data.card_last4}</span>
            </div>
          </div>
        </div>
      ) : null}
      <StripeCard
        condition={!profileData?.card}
        text={!profileData?.card ? 'Add payment method' : 'Edit payment method'}
        onClick={stripeRedirectPayers}
      />
    </div>
  );

  const setupPayouts = (profile_data) => (
    <div className={'bank_cards'}>
      {profile_data?.bank_routing_number && profile_data?.bank_last4 ? (
        <div className={'gap'}>
          <h6>Stripe bank</h6>
          <div className={'card_placeholder'}>
            <div className={'card'}>
              <span>{profile_data.bank_routing_number ?? null}</span>
            </div>
            <div className={'numbers'}>
              <span>•••• {profile_data.bank_last4}</span>
            </div>
          </div>
        </div>
      ) : null}
      <StripeCard
        condition={!profileData?.charges_enabled}
        onClick={stripeRedirectRecipient}
        text={
          !profileData?.charges_enabled ? 'Setup payouts' : 'Payout dashboard'
        }
      />
    </div>
  );

  const handleCheckBoxChanges = (item, index) => {
    let items = { ...item, value: !item.value };
    setCheckboxes((prevState) => [
      ...prevState.slice(0, index),
      items,
      ...prevState.slice(index + 1),
    ]);
    let title = items.title;
    API()
      .put(`/user/profile/settings`, { [title]: items.value })
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  };

  const handleChangePassword = (event) => {
    event.preventDefault();
    if (
      newPassword.value.length > 5 &&
      currentPassword.value.length > 5 &&
      repeatPassword.value.length > 5
    ) {
      if (newPassword.value === repeatPassword.value) {
        API()
          .post(`/user/profile/change_password`, {
            old_password: currentPassword.value,
            new_password: newPassword.value,
          })
          .then((res) => {
            if (res?.status === 200) {
              setCurrentPassword(initialState);
              setNewPassword(initialState);
              setRepeatPassword(initialState);
              changeContinuePopup();
              setTimeout(() => changeContinuePopup(), 2000);
            } else {
              setWrongPassword('server error');
              changeContinuePopup();
              setTimeout(() => changeContinuePopup(), 2000);
            }
          })
          .catch((err) => {
            setWrongPassword(err.response.data.error);
          });
      } else {
        setWrongPassword('Passwords are not the same');
      }
    } else {
      if (currentPassword.value.length <= 5) {
        setCurrentPassword((state) => ({ ...state, error: true }));
      }
      if (newPassword.value.length <= 5) {
        setNewPassword((state) => ({ ...state, error: true }));
      }
      if (repeatPassword.value.length <= 5) {
        setRepeatPassword((state) => ({ ...state, error: true }));
      }
    }
  };

  const personalInfoHandle = (e) => {
    e.preventDefault();

    const {
      about_yourself,
      name,
      surname,
      country,
      categories,
      linkedin_link,
      instagram_link,
      facebook_link,
      ethereum_address,
      seo_text
    } = personalInfo;

    const artistCondition =
      name.value &&
      surname.value &&
      country.value &&
      categories.dataArray.length &&
      about_yourself.value &&
      country.options.some((el) => el === country.value);
    const investorCondition =
      name.value &&
      surname.value &&
      country.value &&
      categories.dataArray.length &&
      country.options.includes(country.value);

    if (store?.role === 'artist' ? artistCondition : investorCondition) {
      let formData = new FormData();
      formData.append('name', name.value);
      formData.append('surname', surname.value);
      formData.append('country', country.value);
      formData.append('categories', categories.dataArray);
      formData.append('ethereum_address', ethereum_address.value);
      if (store?.role === 'artist') {
        if (linkedin_link.value) {
          formData.append('linkedin_link', linkedin_link.value);
        }
        if (instagram_link.value) {
          formData.append('instagram_link', instagram_link.value);
        }
        if (facebook_link.value) {
          formData.append('facebook_link', facebook_link.value);
        }
        formData.append('about_yourself', about_yourself.value);
        formData.append('seo_text', seo_text.value);
      }

      API()
        .post(`/user/profile/edit`, formData)
        .then(() => {
          dispatch(
            setUserData({
              ...store,
              name: name.value,
              country: country.value,
              surname: surname.value,
              // categories: categories.dataArray
            })
          );
          dispatch(setUserCategories(categories.dataArray));
          changeContinuePopup('Your personal data is updated');
          setTimeout(
            () => changeContinuePopup('Your personal data is updated'),
            2000
          );
        });
    } else {
      Object.entries(personalInfo).forEach((el) => {
        if (el[1].value && el[1].options === undefined) {
          console.log(1, el);
        } else if (el[1].options?.length && el[1].dataArray?.length) {
          console.log(2, el);
          // console.log(i)
        } else if (el[1].options?.length && el[1].value) {
          // console.log(i)
          console.log(4, el);
        } else if (el[1].required) {
          console.log(5, el);
          setPersonalInfo((prevState) => ({
            ...prevState,
            [el[0]]: {
              ...prevState[el[0]],
              error: true,
            },
          }));
        } else if (!country.options.includes(country.value)) {
          setPersonalInfo((prevState) => ({
            ...prevState,
            country: {
              ...prevState.country,
              error: true,
            },
          }));
        }
      });
    }
  };

  const unSubscribed = () => {
    API()
      .post(`/user/payment/subscription?subscription=basic`)
      .then(() => {
        bodyOverflow(false);
        setActiveModal({ value: false, show: false });
        setSubscriptionPlan((prevState) => ({
          ...prevState,
          cancel_at_period_end: false,
        }));
        setUserInfo((prevState) => ({
          ...prevState,
          premium: false,
        }));
        changeContinuePopup('You have been successfully unsubscribed.');
        setTimeout(
          () => changeContinuePopup('You have been successfully unsubscribed.'),
          2000
        );
      });
  };

  useEffect(() => {
    API()
      .get('/user/profile/info')
      .then((res) => {
        // console.log('res data info: ', res)
        setUserInfo(res.data);
      });
  }, []);

  useEffect(() => {
    API()
      .get(`/user/payment/subscription?subscription=Emerging&period=yearly`)
      .then((res) => {
        console.log('Res data: ', res);
        setSubscriptionInfo(res.data);
      });
  }, []);

  const deleteAccount = async() => {
    await API().delete("/user/profile/edit")
    forceLogout()
  }

  const categoryList = useMemo(() => {
    if(JSON.parse(localStorage.getItem(Keys.FROM_APP)))
      return personalInfo.categories.options.filter(el => el !== "NFT")
    return personalInfo.categories.options
    // eslint-disable-next-line
  }, [personalInfo.categories.dataArray])

  console.log(subscriptionPlan);
  return [
    <ChangedConfirmedPopup
      successText={successText}
      key={0}
      changingConfirmed={changingConfirmed}
    />,
    <div key={1} className={'settings_wrapper'}>
      <div className={'personal_info_wrap'}>
        <h2>Personal info</h2>
        <div
          style={
            !personalInfo?.loading
              ? {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }
              : null
          }
          className={'personal_info_content'}
        >
          {personalInfo?.loading ? (
            <form
              className={'personal_info_layout'}
              onSubmit={(e) => personalInfoHandle(e)}
            >
              <TextInput
                type={'text'}
                title={'Name'}
                error={personalInfo.name.error}
                newError={personalInfo.name.error}
                errTitle={personalInfo.name.errorMessage}
                value={personalInfo.name.value}
                placeholder={'Name'}
                borderBottomInput
                onChange={({ target }) => {
                  setPersonalInfo((state) => ({
                    ...state,
                    name: {
                      ...state.name,
                      value: target.value,
                      error: false,
                    },
                  }));
                }}
              />
              <TextInput
                type={'text'}
                title={'Surname'}
                error={personalInfo.surname.error}
                newError={personalInfo.surname.error}
                errTitle={personalInfo.surname.errorMessage}
                value={personalInfo.surname.value}
                placeholder={'Surname'}
                borderBottomInput
                onChange={({ target }) => {
                  setPersonalInfo((state) => ({
                    ...state,
                    surname: {
                      ...state.surname,
                      value: target.value,
                      error: false,
                    },
                  }));
                }}
              />

              <TextInput
                  disabled
                  type={'text'}
                  title={'Email'}
                  value={personalInfo.email.value}
                  placeholder={'Email'}
                  borderBottomInput
                  onChange={({ target }) => {
                    setPersonalInfo((state) => ({
                      ...state,
                      surname: {
                        ...state.surname,
                        value: target.value,
                        error: false,
                      },
                    }));
                  }}
              />


              <div className={`half_screen`}>
                <CustomDropdown
                  value={personalInfo.country.value}
                  setInputValue={(val) => setPersonalInfo(val)}
                  errTitle={'Please select your Country'}
                  optionLabel={'country'}
                  error={personalInfo.country.error}
                  newError={personalInfo.country.error}
                  placeholder={'England'}
                  search
                  borderDropdown
                  dropdownSelect
                  newDropdownIcon={
                    <svg
                      width='16'
                      height='10'
                      viewBox='0 0 16 10'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M16 0V1.73624L7.98294 9.77778L0 1.73624V0L7.98294 8.04154L16 0Z'
                        fill='black'
                      />
                    </svg>
                  }
                  title={'Country'}
                  defaultDropdown
                  onChange={({ target }) => {
                    setPersonalInfo((prevState) => ({
                      ...prevState,
                      country: {
                        ...prevState.country,
                        value: target.value,
                        error: null,
                        popup: true,
                      },
                    }));
                  }}
                  dropdownPadding={'5px 16px'}
                  popup={personalInfo.country.popup}
                  options={personalInfo.country.options}
                />
              </div>

              <div
                style={
                  store?.role === 'investor'
                    ? { gridTemplateColumns: '1fr' }
                    : null
                }
                className={'half_screen half_screen_categories'}
              >
                <div
                  className={
                    store?.role === 'investor' ? 'half_screen_country' : ''
                  }
                >
                  <CustomDropdown
                    value={personalInfo.categories.value}
                    title={'Category'}
                    disableMargin={true}
                    setInputValue={(val) => setPersonalInfo(val)}
                    optionLabel={'categories'}
                    placeholder={'Painting'}
                    newDropdownIcon={
                      <svg
                        width='16'
                        height='10'
                        viewBox='0 0 16 10'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M16 0V1.73624L7.98294 9.77778L0 1.73624V0L7.98294 8.04154L16 0Z'
                          fill='black'
                        />
                      </svg>
                    }
                    error={personalInfo.categories.error}
                    newError={personalInfo.categories.error}
                    errTitle={personalInfo.categories.errorMessage}
                    selectCategory
                    borderDropdown
                    defaultDropdown
                    popup={personalInfo.categories.popup}
                    search
                    dataArray={personalInfo.categories.dataArray}
                    onChange={({ target }) => {
                      setPersonalInfo((prevState) => ({
                        ...prevState,
                        categories: {
                          ...prevState.categories,
                          value: target.value,
                          error: false,
                          errorMessage: 'Fill this label',
                        },
                      }));
                    }}
                    dropdownPadding={'5px 16px'}
                    options={categoryList}
                  />
                  {innerWidth > 700 ? (
                    store?.role === 'investor' ? (
                      <Link to={'/profile/categories'}>
                        See Recommendation List >
                      </Link>
                    ) : null
                  ) : null}
                </div>

                {personalInfo.categories.dataArray.length ? (
                  <div className={'full_screen'}>
                    <div
                      className={
                        'categories_options_container upload_work_options_container'
                      }
                    >
                      {personalInfo.categories.dataArray.map((item, index) => {
                        return (
                          <div className={'categories_options'} key={index}>
                            <span>{item}</span>
                            <svg
                              onClick={() => {
                                setPersonalInfo((prevState) => ({
                                  ...prevState,
                                  categories: {
                                    ...prevState.categories,
                                    dataArray:
                                      prevState.categories.dataArray.filter(
                                        (el) => el !== item
                                      ),
                                  },
                                }));
                              }}
                              width='10'
                              height='10'
                              viewBox='0 0 10 10'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M1 9L9 1M9 9L1 1'
                                stroke='white'
                                strokeWidth='2'
                              />
                            </svg>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
                {/*{store?.role === 'investor' &&*/}
                <div className={'title-nft-container'}>
                  <TextInput
                    type={'text'}
                    title={'Ethereum address'}
                    maxLength={42}
                    error={personalInfo.ethereum_address.error}
                    newError={personalInfo.ethereum_address.error}
                    errTitle={personalInfo.ethereum_address.errorMessage}
                    value={personalInfo.ethereum_address.value}
                    placeholder={'Ethereum'}
                    // disabled={toBeDisabled()}
                    borderBottomInput
                    onChange={({ target }) => {
                      console.log(target.value.length);
                      setPersonalInfo((state) => ({
                        ...state,
                        ethereum_address: {
                          ...state.ethereum_address,
                          value: target.value,
                          error: false,
                        },
                      }));
                    }}
                  />
                </div>
                {/*}*/}
              </div>
              {store?.role === 'artist' ? (
                <div className={'full_screen social_links'}>
                  <TextInput
                    type={'text'}
                    title={'Social networks'}
                    newSocialIcon={
                      <svg
                        className={'new_social_link_icon'}
                        width='20'
                        height='20'
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M16.6551 6.41028C16.6076 6.39527 16.5626 6.37902 16.5126 6.36527C16.4526 6.35152 16.3926 6.34027 16.3313 6.33027C16.0938 6.28277 15.8338 6.24902 15.5288 6.24902C12.9213 6.24902 11.2675 8.14529 10.7225 8.8778V6.24902H6.25V19.9991H10.7225V12.4991C10.7225 12.4991 14.1026 7.79154 15.5288 11.2491C15.5288 14.3353 15.5288 19.9991 15.5288 19.9991H20.0001V10.7203C20.0001 8.64279 18.5764 6.91153 16.6551 6.41028Z'
                          fill='black'
                        />
                        <path
                          d='M4.47254 6.24902H0V19.9991H4.47254V6.24902Z'
                          fill='black'
                        />
                        <path
                          d='M2.18752 4.37504C3.39565 4.37504 4.37504 3.39565 4.37504 2.18752C4.37504 0.979386 3.39565 0 2.18752 0C0.979386 0 0 0.979386 0 2.18752C0 3.39565 0.979386 4.37504 2.18752 4.37504Z'
                          fill='black'
                        />
                      </svg>
                    }
                    value={personalInfo.linkedin_link.value}
                    placeholder={'Paste link here...'}
                    borderBottomInput
                    onChange={({ target }) => {
                      setPersonalInfo((state) => ({
                        ...state,
                        linkedin_link: {
                          ...state.linkedin_link,
                          value: target.value,
                        },
                      }));
                    }}
                  />

                  <TextInput
                    type={'text'}
                    newSocialIcon={
                      <svg
                        className={'new_social_link_icon'}
                        width='20'
                        height='20'
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M11.25 6.875V4.375C11.25 3.685 11.81 3.125 12.5 3.125H13.75V0H11.25C9.17875 0 7.5 1.67875 7.5 3.75V6.875H5V10H7.5V20H11.25V10H13.75L15 6.875H11.25Z'
                          fill='black'
                        />
                      </svg>
                    }
                    value={personalInfo.facebook_link.value}
                    placeholder={'Paste link here...'}
                    borderBottomInput
                    onChange={({ target }) => {
                      setPersonalInfo((state) => ({
                        ...state,
                        facebook_link: {
                          ...state.facebook_link,
                          value: target.value,
                        },
                      }));
                    }}
                  />

                  <TextInput
                    type={'text'}
                    newSocialIcon={
                      <svg
                        className={'new_social_link_icon'}
                        width='20'
                        height='20'
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M13.75 0H6.25C2.79875 0 0 2.79875 0 6.25V13.75C0 17.2013 2.79875 20 6.25 20H13.75C17.2013 20 20 17.2013 20 13.75V6.25C20 2.79875 17.2013 0 13.75 0ZM18.125 13.75C18.125 16.1625 16.1625 18.125 13.75 18.125H6.25C3.8375 18.125 1.875 16.1625 1.875 13.75V6.25C1.875 3.8375 3.8375 1.875 6.25 1.875H13.75C16.1625 1.875 18.125 3.8375 18.125 6.25V13.75Z'
                          fill='black'
                        />
                        <path
                          d='M10 5.00098C7.23875 5.00098 5 7.23973 5 10.001C5 12.7622 7.23875 15.001 10 15.001C12.7613 15.001 15 12.7622 15 10.001C15 7.23973 12.7613 5.00098 10 5.00098ZM10 13.126C8.2775 13.126 6.875 11.7235 6.875 10.001C6.875 8.27723 8.2775 6.87598 10 6.87598C11.7225 6.87598 13.125 8.27723 13.125 10.001C13.125 11.7235 11.7225 13.126 10 13.126Z'
                          fill='black'
                        />
                        <path
                          d='M15.3733 5.29148C15.7412 5.29148 16.0395 4.99319 16.0395 4.62523C16.0395 4.25727 15.7412 3.95898 15.3733 3.95898C15.0053 3.95898 14.707 4.25727 14.707 4.62523C14.707 4.99319 15.0053 5.29148 15.3733 5.29148Z'
                          fill='black'
                        />
                      </svg>
                    }
                    value={personalInfo.instagram_link.value}
                    placeholder={'Paste link here...'}
                    borderBottomInput
                    onChange={({ target }) => {
                      setPersonalInfo((state) => ({
                        ...state,
                        instagram_link: {
                          ...state.linkedin_link,
                          value: target.value,
                        },
                      }));
                    }}
                  />
                </div>
              ) : null}
              {store?.role === 'artist' ? (
                <div className={'full_screen about_yourself'}>
                  <ResizeTextBox
                    value={personalInfo.about_yourself.value}
                    error={personalInfo.about_yourself.error}
                    onChange={({ target }) => {
                      setPersonalInfo((prevState) => ({
                        ...prevState,
                        about_yourself: {
                          ...prevState.about_yourself,
                          value: target.value,
                          error: false,
                        },
                      }));
                    }}
                    inputTitle={'About yourself'}
                    errTitle={'Enter at least 10 characters'}
                    borderBottomInput
                  />
                </div>
              ) : null}
              {store?.role === 'artist' ? (
                <div className={'full_screen about_yourself'}>
                  <ResizeTextBox
                    value={personalInfo.seo_text.value}
                    error={personalInfo.seo_text.error}
                    onChange={({ target }) => {
                      setPersonalInfo((prevState) => ({
                        ...prevState,
                        seo_text: {
                          ...prevState.seo_text,
                          value: target.value,
                          error: false,
                        },
                      }));
                    }}
                    inputTitle={'SEO Text'}
                    // errTitle={'Enter at least 10 characters'}
                    borderBottomInput
                  />
                </div>
              ) : null}

              <MainButton
                type={'submit'}
                text={'Save'}
                style={{ margin: '1rem 0 0' }}
                className={'button_black'}
              />
            </form>
          ) : (
            <Loader
              color={{
                '--bgColor': '#000',
                '--size': '48px',
                '--wrapper_size': '40px',
              }}
            />
          )}
        </div>
      </div>
      {store?.role === 'artist' ? (
        <div>
          <h2>Delivery info</h2>
          <div id={'delivery_info'} className={'stripe_fields_wrapper'}>
            <StripeFields
              stripeFields={stripeFields}
              stripeSubmit={stripeSubmit}
              setCanCreateRef={setCanCreateRef}
              setStripeFields={(e) => setStripeFields(e)}
            />
            <div className={'stripe_btn'}>
              <span>Bank accounts or debit cards</span>
              <div className={'layout'}>
                {paymentMethod(profileData)}
                {setupPayouts(profileData)}
                {profileData?.charges_enabled ? (
                  <button
                    className={'delete-payout-button'}
                    onClick={() => setDeletePayoutPopup(!deletePayoutPopup)}
                  >
                    Delete payout
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={'stripe_fields_wrapper'}>
          <div className={'stripe_btn'}>
            <span>Bank accounts or debit cards</span>
            <div className={'layout'}>{paymentMethod(profileData)}</div>
          </div>
        </div>
      )}
      <form
        className={'wrapper_change_password'}
        onSubmit={handleChangePassword}
      >
        <div className={'half_screen'}>
          <h3>Change password</h3>
          <div
            style={
              !wrongPassword ? { margin: '0 0 3.5rem' } : { margin: '0 0 1rem' }
            }
            className={'inputs_wrapper'}
          >
            <TextInput
              title={'Current password'}
              value={currentPassword.value}
              type={'password'}
              errTitle={'Password should contain more than 5 characters'}
              autoComplete={'on'}
              borderBottomInput
              showPass={true}
              newShowPassIcon
              error={currentPassword.error}
              newError={currentPassword.error}
              onChange={(event) => {
                setCurrentPassword({ value: event.target.value, error: false });
                setWrongPassword(null);
              }}
            />
            <TextInput
              title={'New password'}
              type={'password'}
              showPass={true}
              newShowPassIcon
              borderBottomInput
              autoComplete={'on'}
              value={newPassword.value}
              error={newPassword.error}
              newError={newPassword.error}
              errTitle={'Password should contain more than 5 characters'}
              onChange={(event) => {
                setNewPassword({ value: event.target.value, error: false });
                setWrongPassword(null);
              }}
            />
            <TextInput
              title={'Repeat password'}
              type={'password'}
              autoComplete={'on'}
              showPass={true}
              newShowPassIcon
              borderBottomInput
              value={repeatPassword.value}
              error={repeatPassword.error}
              newError={repeatPassword.error}
              errTitle={'Password should contain more than 5 characters'}
              onChange={(event) => {
                setRepeatPassword({ value: event.target.value, error: false });
                setWrongPassword(null);
              }}
            />
          </div>
          <div className={'wrapper_submit_form'}>
            <div className={'text_error'}>{wrongPassword}</div>
            <MainButton
              text={'Apply'}
              className={'button_black'}
              type={'submit'}
            />
          </div>
        </div>
      </form>

      {!JSON.parse(localStorage.getItem(Keys.FROM_APP)) && <div
        style={
          !subscriptionPlan.loading
            ? {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }
            : null
        }
      >
        {subscriptionPlan.loading ? (
          subscriptionPlan.subscription !== 'basic' ? (
            <div className={`subscription_plan subscription_plan_active`}>
              {innerWidth <= 700 ? <img src={plan_top} alt='plan_top' /> : null}
              {userInfo?.premium === true ||
              subscriptionInfo?.cancel_at_period_end ? (
                <h2>Your Subscription plan</h2>
              ) : (
                <h2>Get our special Premium Membership</h2>
              )}
              {userInfo?.premium === true ||
              subscriptionInfo?.cancel_at_period_end ? (
                <div>
                  <div className={'membership_content'}>
                    <div
                      className={'premium-subscription-plan-info__container'}
                    >
                      <div className={'your-subscription-plan-info'}>
                        <span className={'premium-subscription-plan-item'}>
                          Creator:{' '}
                        </span>
                        <span>
                          {subscriptionInfo?.period === 'yearly'
                            ? 'Yearly payment'
                            : 'Monthly payment'}
                        </span>
                      </div>
                      <div className={'your-subscription-plan-info'}>
                        <span className={'premium-subscription-plan-item'}>
                          End date:{' '}
                        </span>
                        <span>
                          {subscriptionInfo?.subscription_end_time}
                          {!subscriptionInfo?.cancel_at_period_end
                            ? ' (Autorenew)'
                            : ' (Autorenew canceled)'}
                        </span>
                      </div>
                    </div>
                    <p>Premium membership includes:</p>
                    <div className={'options'}>
                      {membershipIncludesArtist.map((el, i) => (
                        <div key={i} className={'item'}>
                          <svg
                            width='17'
                            height='12'
                            viewBox='0 0 17 12'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M1 6.33333L5.66667 11L15.6667 1'
                              stroke='white'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                          <span>{el}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <StripeCard
                    condition={!profileData?.card}
                    buttonPremium
                    text={
                      !profileData?.card
                        ? 'Add payment method'
                        : 'Edit payment method'
                    }
                    onClick={stripeRedirectPayers}
                  />
                  {activeModal.show &&
                  !subscriptionPlan.cancel_at_period_end ? (
                    <span
                      className={'unsubscribe'}
                      onClick={() =>
                        setActiveModal({
                          value: true,
                          show: true,
                        })
                      }
                    >
                      Unsubscribe
                    </span>
                  ) : null}
                </div>
              ) : (
                <div className={'membership_content'}>
                  <p>Premium membership includes:</p>
                  <div className={'options'}>
                    {store?.role === 'artist'
                      ? membershipIncludesArtist.map((el, i) => (
                          <div key={i} className={'item'}>
                            <svg
                              width='17'
                              height='12'
                              viewBox='0 0 17 12'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M1 6.33333L5.66667 11L15.6667 1'
                                stroke='white'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                            <span>{el}</span>
                          </div>
                        ))
                      : membershipIncludes.map((el, i) => (
                          <div key={i} className={'item'}>
                            <svg
                              width='17'
                              height='12'
                              viewBox='0 0 17 12'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M1 6.33333L5.66667 11L15.6667 1'
                                stroke='white'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                            <span>{el}</span>
                          </div>
                        ))}
                  </div>
                  <StripeCard
                    condition={!profileData?.card}
                    buttonPremium
                    text={
                      !profileData?.card
                        ? 'Add payment method'
                        : 'Edit payment method'
                    }
                    onClick={stripeRedirectPayers}
                  />
                  {activeModal.show &&
                  !subscriptionPlan.cancel_at_period_end ? (
                    <span
                      className={'unsubscribe'}
                      onClick={() => {
                        setActiveModal({
                          value: true,
                          show: true,
                        });
                      }}
                    >
                      Unsubscribe
                    </span>
                  ) : null}
                </div>
              )}
              {innerWidth <= 700 ? (
                <img src={plan_bottom} alt='plan_bottom' />
              ) : null}
            </div>
          ) : (
            <div className={'subscription_plan'}>
              {innerWidth <= 700 ? <img src={plan_top} alt='plan_top' /> : null}
              <h3>Your Subscription plan</h3>
              <div className={'inline'}>
                <h5>Basic:</h5>
                <span>Simple Analytics</span>
              </div>
              <div className={'inline inline_price'}>
                <h5>Free/</h5>
                <span>year</span>
              </div>
              <Link to={'/subscription-plan'}>Upgrade Plan</Link>
              {innerWidth <= 700 ? (
                <img src={plan_bottom} alt='plan_bottom' />
              ) : null}
            </div>
          )
        ) : (
          <Loader
            color={{
              '--bgColor': '#000',
              '--size': '48px',
              '--wrapper_size': '40px',
            }}
          />
        )}
      </div>}

      {loading ? (
        <div className={'notifications'}>
          <h3>Notification</h3>
          <div className={'checkbox_wrapper'}>
            {checkboxes.map((item, index) => {
              return (
                <CustomCheckbox
                  text={item.title}
                  key={index}
                  value={item.value}
                  handleChange={() => handleCheckBoxChanges(item, index)}
                />
              );
            })}
            <button className={"delete"} onClick={deleteAccount}>
              Delete Profile
            </button>
          </div>
        </div>
      ) : null}
    </div>,

    activeModal.value ? (
      <div key={2} className={'new_modal_wrapper'}>
        <ModalWindow
          activeModal={activeModal}
          nestedObj={'value'}
          setActiveModal={(val) => setActiveModal(val)}
        >
          <h4>Are you sure you want to unsubscribe from premium?</h4>
          <p>It will be active untill the next payment period.</p>
          <div className={'modal-window_buttons'}>
            <MainButton
              type={'button'}
              style={{ background: '#000', color: '#fff' }}
              className={'button_black'}
              text={'Cancel'}
              onClick={() => {
                setActiveModal({ value: false, show: true });
                bodyOverflow(false);
              }}
            />
            <MainButton
              type={'button'}
              className={'button_black'}
              text={'Unsubscribe'}
              onClick={() => unSubscribed()}
            />
          </div>
        </ModalWindow>
      </div>
    ) : null,

    deletePayoutPopup ? (
      <div key={3} className={'new_modal_wrapper'}>
        <ModalWindow
          activeModal={deletePayoutPopup}
          setActiveModal={(val) => setDeletePayoutPopup(val)}
        >
          <div className={'delete-title-container'}>
            <h4>Are you sure you want to delete payout?</h4>
          </div>
          <p>
            You cannot undo this action. Contact support for instructions to set
            up a new payouts account.
          </p>
          <div className={'modal-window_buttons'}>
            <MainButton
              type={'button'}
              style={{ background: '#000', color: '#fff' }}
              className={'button_black'}
              text={'Cancel'}
              onClick={() => {
                setDeletePayoutPopup(!deletePayoutPopup);
                bodyOverflow(false);
              }}
            />
            <MainButton
              type={'button'}
              className={'button_black'}
              text={'Delete'}
              onClick={() => deletePayout()}
            />
          </div>
        </ModalWindow>
      </div>
    ) : null,
  ];
}
