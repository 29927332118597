import moment from "moment";
import {Col, Row, Typography} from "antd";
import {useEffect, useState} from "react";

import API from "../../../api/API";

import StyledButton from "../../../Components/ui/StyledButton";
import BookingItem from "../../../Components/profile/BookingItem";
import {Loader} from "../../../Components/Loader";

import styles from './ProfileBookings.module.css'

export default function ProfileBookings() {
    const [data, setData] = useState({});
    const [isFetching, setIsFetching] = useState(false);
    const [activeTab, setActiveTab] = useState('stays');

    useEffect(() => {
        setIsFetching(true);
        API().get('/listing/bookings', {
            params: {
                type: 'Stay',
                owner_view: 0,
                per_page: 100,
            }
        })
            .then((res) => {
                setData({
                    ...res.data,
                    bookings: res.data?.bookings?.filter((b) => moment(b.date_to).diff(moment(), 'days') >= 0),
                    history: res.data?.bookings?.filter((b) => moment(b.date_to).diff(moment(), 'days') < 0),
                })
            })
            .finally(() => setIsFetching(false))
    }, [])

    const handleChangeTab = (tab) => () => setActiveTab(tab);

    const handleRemoveFromData = (id) => {
        setData((p) => ({
            ...p,
            bookings: p.bookings.filter((b) => b.id !== id),
            history: p.history.filter((b) => b.id !== id)
        }))
    }

    return (
        <main className={styles.wrapper}>
            <div className={styles.tabs}>
                {['studios', 'events', 'stays'].map((tab) => (
                    <StyledButton
                        key={tab}
                        className={styles.tabButton}
                        color={activeTab === tab ? 'black' : 'white'}
                        onClick={handleChangeTab(tab)}
                    >
                        {tab.replace(/^\w/, l => l.toUpperCase())}
                    </StyledButton>
                ))}
            </div>
            <div className={styles.list}>
                {isFetching ? (
                    <Row justify="center">
                        <Col>
                            <Loader/>
                        </Col>
                    </Row>
                ) : (
                    data.bookings?.map((booking) => (
                        <BookingItem key={booking.id} {...booking} onDelete={handleRemoveFromData}/>
                    ))
                )}
            </div>
            <Typography.Title level={4} className={styles.historyTitle}>My booking history</Typography.Title>
            <div className={styles.list}>
                {data.history?.map((booking) => (
                    <BookingItem inactive key={booking.id} {...booking} onDelete={handleRemoveFromData}/>
                ))}
            </div>
        </main>
    )
}