import React, {useEffect, useState} from 'react';
import styles from './PasswordLock.module.css';
import Input from "../CustomComponents/Onboarding/Input";
import MainButton from "../CustomComponents/MainButton";

const PasswordLock = ({children}) => {
  const [password, setPassword] = useState("");
  const [hasAccess, setHasAccess] = useState(false);
  const [error, setError] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    if (password === process.env.REACT_APP_BOOKING_PASSWORD) {
      setHasAccess(true);
      sessionStorage.setItem('artistBnBHasAccess', 'true')
    } else {
      setError(true);
    }
  }

  useEffect(() => {
    if (sessionStorage.getItem('artistBnBHasAccess')) {
      setHasAccess(true);
    }
  }, []);

  return !hasAccess ? (
    <div className={styles.root}>
      <form onSubmit={onSubmit} className={styles.form}>
        <span>Access page</span>

        <Input
          title="Please enter page access code"
          type="password"
          value={password}
          error={error}
          onChange={({target}) => setPassword(target.value.trim())}
        />

        <MainButton type={'submit'}
                    disabled={password.length === 0}
                    className={`button_black ${password.length === 0 ? 'button_black_disabled' : ''}`}
                    text={'Submit'}
        />
      </form>
    </div>
  ) : (
    <>{children}</>
  );
};

export default PasswordLock;
