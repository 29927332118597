import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import Wrapper from '../OnboardingWrapper';
//import artwork_img from '../../../assets/img/onboarding/artwork8.png';
import classes from './styles/artist_reg.module.scss';
import Input from '../../../Components/CustomComponents/Onboarding/Input';
import Button from '../../../Components/CustomComponents/Onboarding/Button';
// import gmailIcon from '../../../assets/img/onboarding/social/gmail.svg';
// import hederalIcon from '../../../assets/img/onboarding/social/hedera.svg';
import regExp from '../../../Constants/regExp';
import Keys from '../../../Constants/helper';
import API from '../../../api/API';
import {clearCookies} from '../../../Constants/clearCookies';
import Error from '../../../Components/CustomComponents/Onboarding/Error';
import {useDispatch, useSelector} from 'react-redux';
import {setUserRegistrationData} from '../../../main-store/auth/actions';
import historyDetectBack from "../HistoryBackDetect";
import {Loader} from "../../../Components/Loader";

const ROLE = "artist";

function Final() {
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const [errors, setErrors] = useState({});
    const [showErrors, setVisibilityErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const store = useSelector(state => state.auth)
    const [artImgArtistReg, setArtImgArtistReg] = useState(null);
    // const [fbToken, setFbToken] = useState(null);

    useEffect(() => {
        API().get(`/admin/landing`)
            .then(res => {
                if (res.data) {
                    setArtImgArtistReg(res.data.artworks_top[Math.floor(Math.random() * res.data.artworks_top.length)])
                    setLoading(true)
                }
            })
    }, [])

    useEffect(() => {
        if (showErrors) {
            _isFormValid();
        }
        // eslint-disable-next-line
    }, [name, surname, email, password, confirm]);

    // const resetAuthData = () => {
    //     // setFbToken(null)
    //     sessionStorage.removeItem(Keys.VERIFIED_POPUP)
    //     sessionStorage.removeItem(Keys.LANDING_ROLE)
    // }

    // const loginSuccess = res => {
    //     if (res.accessToken) {
    //         // setFbToken(res.accessToken)
    //         dispatch(setAuthVariant(true));
    //         API().post(`/user/oauth`, {"token": res.accessToken, "role": ROLE})
    //             .then(res => {
    //                 clearCookies();
    //                 let userData = JSON.stringify({
    //                     name: res.data.name,
    //                     role: ROLE,
    //                     avatar: res.data.avatar ? res.data.avatar : null
    //                 })
    //                 localStorage.setItem(Keys.JWT_TOKEN_REGISTRATION, res.data.access_token)
    //                 dispatch(setUserData(userData))
    //                 window.location.pathname = '/main-info'
    //                 resetAuthData()
    //             })
    //             .catch((er) => console.log(er))
    //     }
    // }


    const _isFormValid = () => {
        const errors = {};

        if (!name) {
            errors.name = "Empty";
        }

        if (!surname) {
            errors.surname = "Empty";
        }

        if (!email) {
            errors.email = "Should not be empty";
        } else if (!regExp.email.test(email)) {
            errors.email = "Enter valid email";
        }

        if (password.length < 6) {
            errors.password = "Enter at least 6 characters";
        }

        if (!confirm) {
            errors.confirm = "Should not be empty";
        } else if (password !== confirm) {
            errors.confirm = "Password is not similar";
        }

        if (Object.keys(errors).length) {
            setErrors(errors);
            return false;
        } else {
            setErrors({});
        }

        return true;
    }

    useEffect(() => {
        // console.log(store.user_registration_data)
        if (!store.user_registration_data) {
            history.push('/registration/type')
        }
    }, [store.user_registration_data, history])

    const _onSubmit = e => {
        e.preventDefault();
        if (!_isFormValid()) {
            setVisibilityErrors(true);
            return;
        }

        API().post('/user/registration/check_email', {email})
            .then(response => {
                if (response.data.result) {
                    // eslint-disable-next-line no-throw-literal
                    throw 'The email is already in use';
                }

                return API().post(`/user/registration/email`, {
                    name,
                    surname,
                    email,
                    password,
                    role: ROLE,
                    receive_email: false
                })
            })
            .then(res => {
                // console.log(res);
                clearCookies();
                let {nextHistory} = historyDetectBack(store.user_registration_data, store.active_history)
                localStorage.setItem(Keys.JWT_TOKEN_REGISTRATION, res.data.access_token)
                dispatch(setUserRegistrationData({
                    ...store.user_registration_data,
                    name,
                    surname,
                    email,
                    receive_email: false
                }))
                // console.log(nextHistory);
                history.push(nextHistory)
            })
            .catch(e => {
                if (e === 'The email is already in use') {
                    setErrors({email: e});
                }
            });
    }


    return (
        <Wrapper>
            {artImgArtistReg && loading ?
                <Wrapper.Picture src={artImgArtistReg.photo} artAuthor={artImgArtistReg.user.name}
                                 authorAvatar={artImgArtistReg.user.avatar}/>
                : <Loader color={{
                    '--bgColor': '#000',
                    '--size': '48px',
                    '--wrapper_size': '40px'
                }}/>}
            <Wrapper.Form>
                <div className={classes.wrapper}>
                    <p className={classes.uptitle}>In order to submit your application you must register.</p>
                    <h1 className={classes.title}>Register to continue</h1>
                    <form className={classes.form} onSubmit={_onSubmit}>
                        <div className={classes.form_row_2}>
                            <div className={classes.form_col}>
                                <Input
                                    className={classes.form_input}
                                    title="Name"
                                    value={name}
                                    onChange={({target}) => setName(target.value.trim())}
                                    error={errors.name}
                                />
                            </div>
                            <div className={classes.form_col}>
                                <Input
                                    className={classes.form_input}
                                    title="Surname"
                                    value={surname}
                                    onChange={({target}) => setSurname(target.value.trim())}
                                    error={errors.surname}
                                />
                            </div>
                        </div>
                        <Input
                            className={classes.form__input}
                            title="E-Mail"
                            type="email"
                            value={email}
                            onChange={({target}) => setEmail(target.value.trim())}
                            error={errors.email}
                        />
                        <Input
                            className={classes.form__input}
                            title="Password"
                            type="password"
                            value={password}
                            onChange={({target}) => setPassword(target.value.trim())}
                            error={errors.password}
                        />
                        <Input
                            className={classes.form__input}
                            title="Confirm Password"
                            type="password"
                            value={confirm}
                            onChange={({target}) => setConfirm(target.value.trim())}
                            error={errors.confirm}
                        />
                        <div className={classes.errors}>
                            {Object.keys(errors).map(key => {
                                return <Error text={errors[key]} key={key}/>
                            })}
                        </div>
                        <div className={classes.form_footer}>
                            <div className={classes.footer_btns}>
                                <Button type="submit" onClick={_onSubmit}>Continue &#62;</Button>
                            </div>
                            {/*<Social className={classes.footer_social}>*/}
                            {/*    /!*<Social.Item icon={gmailIcon} text="Register with Gmail" onClick={null} />*!/*/}
                            {/*    <FacebookLogin*/}
                            {/*        appId="2618139161816939"*/}
                            {/*        autoLoad={false}*/}
                            {/*        fields="name,email,picture"*/}
                            {/*        callback={loginSuccess}*/}
                            {/*        render={renderProps => <Social.Item type="fb" icon={fbIcon}*/}
                            {/*                                            text="Register with Facebook"*/}
                            {/*                                            onClick={renderProps.onClick}/>}*/}
                            {/*    />*/}
                            {/*    /!*<Social.Item icon={hederalIcon} text="Register with Hedera" onClick={null} />*!/*/}
                            {/*</Social>*/}
                        </div>
                    </form>
                </div>
            </Wrapper.Form>
        </Wrapper>
    )
}

export default Final;