import React, {useEffect, useRef, useState} from 'react';
import '../styles/new_upload_work.css';
import {Link, useHistory} from "react-router-dom";
import TextInput from "../CustomComponents/TextInput";
import CustomDropdown from "../CustomComponents/Dropdown";
import greyChevron from "../../assets/img/personal_registration/chevron-input-down.svg";
import API from "../../api/API";
import {ResizeTextBox} from "../ResizeTextBox";
import DropzonePhotoContainer from "../DropzonePhotoContainer";
import regExp from "../../Constants/regExp";
import NewRangeSliderMultiple from "../CustomComponents/NewRangeSliderMultiple";
import RadioButton from "../CustomComponents/RadioButton";
import MainButton from "../CustomComponents/MainButton";
import DropzoneFileContainer from "../DropzoneFileContainer";
import CustomCheckbox from "../CustomComponents/CheckBox";
import {setActiveUpload} from "../../main-store/auth/actions";
import ChangedConfirmedPopup from "../CustomComponents/ChangedConfirmedPopup";
import useBottomPopup from "../../Constants/useBottomPopup";
import {useDispatch} from "react-redux";
import AuthenticityCertificate from "../AuthenticityCertificate";
import {bodyOverflow} from "../../Constants/bodyOverflow";
import {Loader} from "../Loader";
import useResize from "../../Constants/useResize";

const initialState = {
    type: {
        value: 'classic'
    },
    styles: {
        value: '',
        options: null,
        popup: false,
        required: true,
        error: null,
        errorMessage: 'Choose style',
        dataArray: []
    },
    mediums: {
        value: '',
        options: null,
        popup: false,
        required: true,
        error: null,
        errorMessage: 'Choose style',
        dataArray: []
    },
    categories: {
        value: '',
        options: null,
        required: true,
        error: null,
        errorMessage: 'Choose category',
        popup: false,
        dataArray: [],
    },
    art_title: {
        value: '',
        errorMessage: 'Fill this label',
        required: true,
        error: null
    },
    year_of_origination: {
        value: '',
        errorMessage: 'Fill this label',
        required: true,
        error: false
    },
    art_description: {
        value: '',
        errorMessage: 'Fill this label',
        required: true,
        error: null
    },
    agree_terms: {
        value: false,
        required: true,
        errorMessage: 'You must agree to the Terms of service to upload artwork',
        error: null
    },
    publication: {
        value: '',
        error: null,
        required: true,
        errorMessage: 'Fill this label',
    },
    publication_proofs: {
        files: [],
        error: null,
        errorMessage: null
    },
    exhibition_participation: {
        value: '',
        error: null,
        required: true,
        errorMessage: 'Fill this label',
    },
    exhibition_participation_proofs: {
        files: [],
        error: null,
        errorMessage: null
    },
    optional_info: {
        value: null,
        error: null,
        required: true,
        errorMessage: 'Select the packaging type',
    },
    materials: {
        value: '',
        options: null,
        required: true,
        error: null,
        popup: false,
        dataArray: [],
        errorMessage: 'Choose materials',
    },
    cost_of_materials: {
        value: '',
        error: null,
        required: true,
        errorMessage: 'Fill this label',
    },
    art_width: {
        value: 0,
        required: true,
        error: null,
        max: 500,
    },
    art_depth: {
        value: 0,
        required: true,
        error: null,
        max: 500,
    },
    art_height: {
        value: 0,
        required: true,
        error: null,
        max: 500,
    },
    weight: {
        value: '',
        options: ['Small (up to 2kg)', 'Medium (up to 4kg)', 'Large (up to 8kg)'],
        required: true,
        error: null,
        popup: false,
        errorMessage: null
    },
    final: {
        required: true,
        error: null,
        errorMessage: null
    }
}

export default function NewUploadWork(props) {

    const hoverRef = useRef();

    const {
        editMode, setShowUploadWork, user_subscription, setUploadEnd, setEditMode
    } = props;

    const uploadDispatch = useDispatch();
    const history = useHistory();
    const {innerWidth} = useResize();

    const [files, setFiles] = useState([{}, {}, {}, {}, {}]);
    const [artworkFilesError, setArtworkFilesError] = useState(false);
    const [fieldsError, setFieldsError] = useState(false);
    const [soldError, setSoldError] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [uploadWorkData, setUploadWorkData] = useState(() => initialState);
    const [activePopover, setActivePopover] = useState(false);
    const [pendingButton, setPendingButton] = useState(false)
    const [showPreview, setShowPreview] = useState(false);
    const [estimatePrice, setEstimatePrice] = useState(null);
    const [loading, setLoading] = useState(false);
    let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();

    useEffect(() => {
        API().get(`/user/artwork/data`)
            .then(res => {
                setUploadWorkData(state => ({
                    ...state,
                    styles: {...state.styles, options: res.data.styles},
                    categories: {...state.categories, options: res.data.categories.filter(i => i.toLowerCase() !== "nft")},
                    mediums: {...state.mediums, options: res.data.mediums},
                    materials: {...state.materials, options: res.data.materials},
                }))
                setEstimatePrice(res.data.price)
            })
            .then(() => setLoading(true))
    }, [])

    useEffect(() => {
        if (editMode) {
            API().get(`/user/artwork?id=${editMode}`)
                .then(res => {
                    const findActiveWeight = uploadWorkData?.weight?.options.find(i => {
                        if (i.includes(res.data.weight)) {
                            return i
                        } else {
                            return null
                        }
                    })

                    res.data.pictures.forEach((el, i) => {
                        setFiles(prevState => ([
                            ...prevState.slice(0, i),
                            [{...prevState[i], preview: el.url}],
                            ...prevState.slice(i + 1)
                        ]))
                    })

                    // console.log('----- Files -----', files)

                    setUploadWorkData(state => ({
                        ...state,
                        styles: {...state.styles, dataArray: res.data.styles},
                        mediums: {...state.mediums, dataArray: res.data.mediums},
                        materials: {...state.materials, dataArray: res.data.materials},
                        categories: {...state.categories, dataArray: [res.data.category]},
                        type: {...state.type, value: res.data.category},
                        art_depth: {...state.art_depth, value: res.data.depth},
                        art_height: {...state.art_height, value: res.data.height},
                        art_width: {...state.art_width, value: res.data.width},
                        hidden: res.data.hidden,
                        weight: {...state.weight, value: findActiveWeight ?? null},
                        optional_info: {...state.optional_info, value: res.data.packaging_type},
                        art_description: {...state.art_description, value: res.data.description},
                        art_title: {...state.art_title, value: res.data.title},
                        final: {...state.final, value: res.data.price},
                        agree_terms: {...state, value: true},
                        publication: {...state.publication, value: res.data.publication},
                        exhibition_participation: {
                            ...state.exhibition_participation,
                            value: res.data.exhibition_participation
                        },
                        exhibition_participation_proofs: {
                            ...state.exhibition_participation_proofs,
                            files: res.data.exhibition_participation_proofs
                        },
                        publication_proofs: {...state.publication_proofs, files: res.data.publication_proofs},
                        cost_of_materials: {...state.cost_of_materials, value: res.data.cost_of_materials},
                        year_of_origination: {...state.year_of_origination, value: res.data.year_of_origination},
                    }))
                })
        }
// eslint-disable-next-line
    }, [editMode, uploadWorkData.weight.options, /*files*/])

    const setInputPriceRange = (value, rangeValue, category) => {
        setUploadWorkData(prevState => ({
            ...prevState,
            [category]: {
                ...prevState[category],
                [rangeValue]: Number(value),
                error: false,
            }
        }))
    }

    const setPriceRange = (event, val) => {
        setUploadWorkData(prevState => ({
            ...prevState,
            [val]: {
                ...prevState[val],
                value: Number(event.value),
                error: false,
            }
        }))
    }

    const measureHandle = (title, obj) => (
        <div className={'upload_work_params'}>
            <div className={'upload_work_params__info'}>
                <span>{title}</span>
                <div className={'input_range'}>
                    <TextInput value={uploadWorkData[obj].value}
                               placeholder={500}
                               error={uploadWorkData[obj].error}
                               borderBottomInput
                               newError
                               errMessageHidden
                               onChange={({target}) => {
                                   let {value} = target
                                   if (regExp.only_number.test(value) && value <= 500) {
                                       setInputPriceRange(value, 'value', obj)
                                   }
                               }}/>
                </div>
            </div>
            <NewRangeSliderMultiple setValue={e => setPriceRange(e, obj)}
                                    value={uploadWorkData[obj].value}
                                    step={25}
                                    error={uploadWorkData[obj].error}
                                    max_price={500}/>
            {uploadWorkData[obj].error ? handleCustomError('1.5rem 0 0', 'This value cannot be 0') : null}
        </div>
    )

    useEffect(() => () => {
        //Make sure to revoke the data uris to avoid memory leaks
        files.map(el => el).forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    const uploadProofWrap = (inputTitle, objHandle, filesArray) => {
        return (
            <div className={'upload_proof_wrap'}>
                <ResizeTextBox borderBottomInput
                               value={uploadWorkData[objHandle].value}
                               error={uploadWorkData[objHandle].error}
                               inputTitle={inputTitle}
                               disabled={!!editMode}
                               onChange={({target}) => setUploadWorkData(prevState => ({
                                   ...prevState,
                                   [objHandle]: {
                                       ...prevState[objHandle],
                                       value: target.value,
                                       error: false
                                   }
                               }))}
                               blockStyle={{
                                   margin: '0 0 1.5rem',
                                   display: 'grid',
                                   gridTemplateColumns: '1fr',
                                   alignItems: 'center'
                               }}/>
                <div style={editMode ? {pointerEvents: 'none'} : null} className={'proofs_wrap'}>
                    <DropzoneFileContainer data={uploadWorkData}
                                           disabled={!!editMode}
                                           setData={e => setUploadWorkData(e)}
                                           filesArray={filesArray}/>
                </div>
            </div>
        )
    }

    const infoDetails = (objValues, category, title, active_category) => {
        return (
            <div>
                <CustomDropdown value={objValues.value}
                                filterItem={true}
                                title={title}
                                disableMargin={true}
                                setInputValue={val => {
                                    setUploadWorkData(val)
                                }}
                                optionLabel={category}
                                placeholder={category}
                                newDropdownIcon={<svg width="16" height="10" viewBox="0 0 16 10" fill="none"
                                                      xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16 0V1.73624L7.98294 9.77778L0 1.73624V0L7.98294 8.04154L16 0Z"
                                        fill="black"/>
                                </svg>
                                }
                                error={objValues.error}
                                newError={objValues.error}
                                active_category={active_category}
                                errorTitle={objValues.errorMessage}
                                selectCategory
                                borderDropdown
                                defaultDropdown
                                popup={objValues.popup}
                                search
                                dataArray={objValues.dataArray}
                                onChange={({target}) => {
                                    setUploadWorkData(prevState => ({
                                        ...prevState,
                                        [category]: {
                                            ...prevState[category],
                                            value: target.value,
                                            error: false,
                                            errorMessage: 'Fill this label',
                                        }
                                    }))
                                }}
                                dropdownPadding={'5px 16px'}
                                dropdownIcon={greyChevron}
                                options={objValues.options}
                />
                {objValues.dataArray.length ?
                    <div className={'categories_options_container upload_work_options_container'}>
                        {objValues.dataArray.map((item, index) => {
                            return (
                                <div className={'categories_options'}
                                     key={index}>
                                    <span>{item}</span>
                                    <svg
                                        onClick={() => {
                                            setUploadWorkData(prevState => ({
                                                ...prevState,
                                                [category]: {
                                                    ...prevState[category],
                                                    dataArray: prevState[category].dataArray.filter(el => el !== item)
                                                }
                                            }))
                                        }} width="10" height="10" viewBox="0 0 10 10" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 9L9 1M9 9L1 1" stroke="white" strokeWidth="2"/>
                                    </svg>
                                </div>
                            )
                        })}
                    </div> : null}
            </div>
        )
    }

    const handleCustomError = (margin, text) => (
        <div style={{margin: margin}} className={'new_error_wrapper'}>
            <svg width="17" height="15" viewBox="0 0 17 15" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M16.6427 12.94L9.5819 0.710387C9.06221 -0.189756 7.7637 -0.190085 7.24381 0.710387L0.182995 12.94C-0.336696 13.8402 0.312277 14.9649 1.35205 14.9649H15.4736C16.513 14.9649 17.1626 13.8405 16.6427 12.94ZM15.4736 13.7278H1.35205C1.26431 13.7278 1.21032 13.6347 1.25429 13.5585L8.31507 1.3289C8.35894 1.25288 8.4666 1.25272 8.51057 1.3289L15.5714 13.5585C15.6153 13.6345 15.5616 13.7278 15.4736 13.7278Z"
                    fill="#FF4949"/>
                <path
                    d="M8.41148 5.16602C8.0699 5.16602 7.79297 5.44295 7.79297 5.78452V9.33008C7.79297 9.67166 8.0699 9.94859 8.41148 9.94859C8.75305 9.94859 9.02999 9.67166 9.02999 9.33008V5.78452C9.03002 5.44295 8.75309 5.16602 8.41148 5.16602Z"
                    fill="#FF4949"/>
                <path
                    d="M8.41148 10.752C8.0699 10.752 7.79297 11.0289 7.79297 11.3705V11.7398C7.79297 12.0814 8.0699 12.3583 8.41148 12.3583C8.75305 12.3583 9.02999 12.0814 9.02999 11.7398V11.3705C9.03002 11.0288 8.75309 10.752 8.41148 10.752Z"
                    fill="#FF4949"/>
            </svg>
            <span>{text}</span>
        </div>
    )

    const handleSubmit = (e) => {
        e?.preventDefault();
        setFieldsError(false);
        const {
            art_title, art_description, agree_terms, final, art_depth,
            art_height, art_width, exhibition_participation, materials, exhibition_participation_proofs,
            publication_proofs, cost_of_materials, publication, styles, mediums, type,
            optional_info, categories, year_of_origination, weight
        } = uploadWorkData;

        let _weight = weight.value.split('kg)')[0].split(' ').pop()

        if (editMode) {
            const condition = art_title.value && (art_depth.value && art_depth.value > 0) && agree_terms.value && final.value && (art_height.value && art_height.value > 0) && (art_width.value && art_width.value > 0) && styles.dataArray.length && mediums.dataArray.length && categories.dataArray.length &&
                optional_info.value && materials.dataArray.length && cost_of_materials.value && year_of_origination.value && art_description.value
                && weight.value
            const description_length_condition = art_description.value.replaceAll(" ", "").length >= 350

            if (condition && files.some(el => Object.keys(el).length) && description_length_condition) {
                setPendingButton(true)
                API().put(`/user/artwork`, {
                    id: editMode,
                    title: art_title.value,
                    description: art_description.value,
                    depth: art_depth.value,
                    width: art_width.value,
                    weight: _weight,
                    height: art_height.value,
                    packaging_type: optional_info.value,
                    styles: styles.dataArray,
                    mediums: mediums.dataArray,
                    cost_of_materials: cost_of_materials.value,
                    year_of_origination: year_of_origination.value,
                    category: categories.dataArray,
                    materials: materials.dataArray,
                    price: final.value,
                })
                    .then(() => {
                        // console.log(res)
                        changeContinuePopup('You artwork is updated')
                        setTimeout(() => {
                            changeContinuePopup('You artwork is updated')
                            history.push('/profile/portfolio')
                            window.scrollTo(0, 0)
                            setShowUploadWork(false)
                            setPendingButton(false)
                            uploadDispatch(setActiveUpload(false))
                            setIsFinished(false)
                            setUploadEnd(true)
                        }, 1000)
                    })
                    .catch(err => {
                        console.log(err)
                        if (err.response.data.error === 'sold artwork cannot be changed') {
                            setSoldError(true)
                            setPendingButton(false)
                        }
                    })
            } else {
                setFieldsError(true);
                if (!description_length_condition) {
                    setUploadWorkData(state => ({
                        ...state,
                        art_description: {
                            ...state.art_description,
                            error: true,
                            errorMessage: "Min length is 350 characters, excluding spaces"
                        }
                    }))
                }
                if (files.every(el => !Object.keys(el).length)) {
                    setArtworkFilesError(true)
                }
                Object.entries(uploadWorkData).forEach(i => {

                    if (i[1].value && i[1].options === undefined) {
                        // console.log(1, i)
                    } else if (i[1].options?.length && i[1].dataArray?.length) {
                        // console.log(2, i)
                        // console.log(i)
                    } else if (i[1].options?.length && i[1].value) {
                        // console.log(i)
                        // console.log(4, i)
                    } else if (i[1].files?.length) {
                        // console.log(3, i)
                    } else if (i[1].required) {
                        // console.log(i[1])
                        setUploadWorkData(state => ({
                            ...state,
                            [i[0]]: {
                                ...state[i[0]],
                                error: true
                            }
                        }))
                    }
                })
            }
        } else {

            let formData = new FormData();

            const condition = art_title.value && (art_depth.value && art_depth.value > 0) && agree_terms.value && final.value && (art_height.value && art_height.value > 0) && (art_width.value && art_width.value > 0) && publication.value && styles.dataArray.length && mediums.dataArray.length && categories.dataArray.length && exhibition_participation.value &&
                optional_info.value && materials.dataArray.length && cost_of_materials.value && year_of_origination.value && art_description.value
                && weight.value
            const description_length_condition = art_description.value.replaceAll(" ", "").length >= 350

            if (condition && files.some(el => Object.keys(el).length) && description_length_condition) {
                bodyOverflow(true);
                setShowPreview(true);

                if (isFinished) {
                    bodyOverflow(false);
                    setShowPreview(false);
                    if (art_description.value) {
                        formData.append('description', art_description.value)
                    }
                    let _weight = weight.value.split('kg)')[0].split(' ').pop()
                    formData.append('agree_terms', agree_terms.value)
                    formData.append('title', art_title.value)
                    formData.append('depth', art_depth.value)
                    formData.append('width', art_width.value)
                    formData.append('weight', _weight)
                    formData.append('price', final.value)
                    formData.append('packaging_type', optional_info.value)
                    formData.append('height', art_height.value)
                    formData.append('type', type.value)
                    formData.append('styles', styles.dataArray)
                    formData.append('mediums', mediums.dataArray)
                    formData.append('cost_of_materials', cost_of_materials.value)
                    formData.append('year_of_origination', year_of_origination.value)
                    formData.append('category', categories.dataArray)
                    formData.append('materials', materials.dataArray)
                    formData.append('publication', publication.value)
                    formData.append('exhibition_participation', exhibition_participation.value)

                    files.forEach(item => {
                        if (Object.keys(item).length) {
                            formData.append('pictures', item[0])
                        }
                    })

                    if (publication_proofs.files.length) {
                        publication_proofs.files.forEach(i => {
                            // console.log(i)
                            formData.append('publication_proofs', i)
                        })
                    }

                    if (exhibition_participation_proofs.files.length) {
                        exhibition_participation_proofs.files.forEach(i => {
                            // console.log(i)
                            formData.append('exhibition_participation_proofs', i)
                        })
                    }

                    // for (let b of formData.entries()) {
                    //     console.log(b);
                    // }

                    setPendingButton(true)
                    API().post(`/user/artwork`, formData)
                        .then((res) => {
                            changeContinuePopup('You artwork is uploaded')
                            setTimeout(() => {
                                setPendingButton(false)
                                changeContinuePopup('You artwork is uploaded')
                                if (user_subscription === 'basic') {
                                    history.push('/subscription-plan')
                                } else {
                                    history.push('/profile/portfolio')
                                }
                                window.scrollTo(0, 0)
                                setShowUploadWork(false)
                                uploadDispatch(setActiveUpload(false))
                                setIsFinished(false)
                                setUploadEnd(true)
                            }, 1000)
                        })
                }
            } else {
                setFieldsError(true);
                if (!description_length_condition) {
                    setUploadWorkData(state => ({
                        ...state,
                        art_description: {
                            ...state.art_description,
                            error: true,
                            errorMessage: "Min length is 350 characters, excluding spaces"
                        }
                    }))
                }
                if (files.every(el => !Object.keys(el).length)) {
                    setArtworkFilesError(true)
                }
                Object.entries(uploadWorkData).forEach(i => {

                    if (i[1].value && i[1].options === undefined) {
                        // console.log(1, i)
                    } else if (i[1].options?.length && i[1].dataArray?.length) {
                        // console.log(2, i)
                        // console.log(i)
                    } else if (i[1].options?.length && i[1].value) {
                        // console.log(i)
                        // console.log(4, i)
                    } else if (i[1].files?.length) {
                        // console.log(3, i)
                    } else if (i[1].required) {
                        // console.log(i[1])
                        setUploadWorkData(state => ({
                            ...state,
                            [i[0]]: {
                                ...state[i[0]],
                                error: true
                            }
                        }))
                    }
                })
            }
        }
    }

    console.log('uploadWorkData: ', uploadWorkData?.styles?.options)

    return [
        <ChangedConfirmedPopup successText={successText}
                               key={1}
                               changingConfirmed={changingConfirmed}/>,
        <form key={0} className={'upload_work_wrapper'} onSubmit={e => handleSubmit(e)}>
            {uploadWorkData.hidden
                ? <div className={'artwork_hidden'}>
                    <span>Your artwork is hidden!</span>
                </div> : null}
            <div className={'title_wrap'}>
                <h2>{editMode ? 'Edit' : 'Upload'} work</h2>
                <div className={`tooltip_wrap ${activePopover ? 'tooltip_wrap_active' : ''}`}>
                    <svg
                        onMouseEnter={() => innerWidth > 992 ? setActivePopover(true) : null}
                        onMouseOut={() => {
                            if (innerWidth > 992) {
                                setTimeout(() => {
                                    document.addEventListener('mouseover', (e) => {
                                        if (e.target !== hoverRef.current) {
                                            setActivePopover(false)
                                        } else {
                                            setActivePopover(true)
                                        }
                                    });
                                }, 1000)
                            }
                        }}
                        width="16" height="16" viewBox="0 0 16 16" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.74375 4.94844C9.275 4.5375 8.65625 4.3125 8 4.3125C7.34375 4.3125 6.725 4.53906 6.25625 4.94844C5.76875 5.375 5.5 5.94844 5.5 6.5625V6.68125C5.5 6.75 5.55625 6.80625 5.625 6.80625H6.375C6.44375 6.80625 6.5 6.75 6.5 6.68125V6.5625C6.5 5.87344 7.17344 5.3125 8 5.3125C8.82656 5.3125 9.5 5.87344 9.5 6.5625C9.5 7.04844 9.15625 7.49375 8.62344 7.69844C8.29219 7.825 8.01094 8.04687 7.80937 8.3375C7.60469 8.63437 7.49844 8.99062 7.49844 9.35156V9.6875C7.49844 9.75625 7.55469 9.8125 7.62344 9.8125H8.37344C8.44219 9.8125 8.49844 9.75625 8.49844 9.6875V9.33281C8.49925 9.18113 8.54574 9.03321 8.63187 8.90834C8.71799 8.78347 8.83975 8.68746 8.98125 8.63281C9.90312 8.27813 10.4984 7.46563 10.4984 6.5625C10.5 5.94844 10.2312 5.375 9.74375 4.94844ZM7.375 11.4375C7.375 11.6033 7.44085 11.7622 7.55806 11.8794C7.67527 11.9967 7.83424 12.0625 8 12.0625C8.16576 12.0625 8.32473 11.9967 8.44194 11.8794C8.55915 11.7622 8.625 11.6033 8.625 11.4375C8.625 11.2717 8.55915 11.1128 8.44194 10.9956C8.32473 10.8783 8.16576 10.8125 8 10.8125C7.83424 10.8125 7.67527 10.8783 7.55806 10.9956C7.44085 11.1128 7.375 11.2717 7.375 11.4375Z"
                            fill="black"/>
                        <rect x="1.5" y="1.5" width="13" height="13" stroke="black"/>
                    </svg>
                    <div ref={hoverRef} className={'tooltip_content'}>
                        <Link to={'/faq/what_is_artsted'}>View the guidelines for the artists / FAQ</Link>
                    </div>
                </div>
            </div>
            <p className={'subtitle'}>Present your work in the best light! Make sure to include appealing
                high-quality
                images and photos.</p>
            {editMode ?
                <p className={'subtitle'}><b>You can not edit the pictures on this stage.</b></p> : null}
            <div style={editMode ? {pointerEvents: 'none'} : artworkFilesError ? {margin: '0'} : null}
                 className={'upload_photos_wrapper'}>
                <div>
                    {console.log('!!!!!!!!: ', files)}
                    <DropzonePhotoContainer data={files}
                                            setData={e => setFiles(e)}
                                            index={0}
                                            key={0}
                                            artworkFilesError={artworkFilesError}
                                            setArtworkFilesError={e => setArtworkFilesError(e)}
                                            biggerContainer/>
                </div>
                <div className={'small_previews'}>
                    {[1, 2, 3, 4].map((el) => (
                        <DropzonePhotoContainer data={files}
                                                setData={e => setFiles(e)}
                                                setArtworkFilesError={e => setArtworkFilesError(e)}
                                                index={el}
                                                key={el}/>
                    ))}
                </div>
            </div>
            {artworkFilesError
                ? handleCustomError('1rem 0 1.5rem', 'At least one picture must be uploaded') : null}

            <div className={'basic_info'}>
                <TextInput
                    type={'text'}
                    title={'Title'}
                    error={uploadWorkData.art_title.error}
                    newError={uploadWorkData.art_title.error}
                    errTitle={uploadWorkData.art_title.errorMessage}
                    value={uploadWorkData.art_title.value}
                    placeholder={'Artwork title'}
                    style={{gridColumn: "-1/1"}}
                    borderBottomInput
                    onChange={({target}) => {
                        setUploadWorkData(state => ({
                                ...state,
                                art_title: {
                                    ...state.art_title,
                                    value: target.value,
                                    error: false,
                                }
                            }
                        ))
                    }}
                />

                <TextInput
                    type={'text'}
                    title={'Year'}
                    error={uploadWorkData.year_of_origination.error}
                    newError={uploadWorkData.year_of_origination.error}
                    errTitle={uploadWorkData.year_of_origination.errorMessage}
                    value={uploadWorkData.year_of_origination.value}
                    placeholder={'2018'}
                    borderBottomInput
                    onChange={({target}) => {
                        if (regExp.only_number.test(target.value) && target.value.length <= 10) {
                            setUploadWorkData(state => ({
                                    ...state,
                                    year_of_origination: {
                                        ...state.year_of_origination,
                                        value: target.value,
                                        error: false,
                                    }
                                }
                            ))
                        }
                    }}
                />

                {loading && infoDetails(uploadWorkData.categories, 'categories', 'Categories', 1)}
                {loading && infoDetails(uploadWorkData.styles, 'styles', 'Style')}
                {loading && infoDetails(uploadWorkData.mediums, 'mediums', 'Mediums')}

            </div>

            <div className={'resize_description'}>
                <ResizeTextBox inputTitle={'Description'}
                               placeholder={'About artwork'}
                               borderBottomInput
                               value={uploadWorkData.art_description.value}
                               error={uploadWorkData.art_description.error}
                               errorMessage={uploadWorkData.art_description.errorMessage}
                               onChange={({target}) => setUploadWorkData(prevState => ({
                                   ...prevState,
                                   art_description: {
                                       ...prevState.art_description,
                                       value: target.value,
                                       error: false
                                   }
                               }))}
                               blockStyle={{
                                   margin: '0',
                                   display: 'grid',
                                   gridTemplateColumns: '1fr',
                                   alignItems: 'center'
                               }}/>
            </div>
            <div className={'price_calculator_wrapper'}>
                <h3>Price Calculator</h3>
                <div className={'price_calculator_content'}>
                    <div className={'measures_wrapper'}>
                        {measureHandle('Height, cm', 'art_height')}
                        {measureHandle('Width, cm', 'art_width')}
                        {measureHandle('Depth, cm', 'art_depth')}
                    </div>
                    <div className={'materials_wrapper'}>
                        <CustomDropdown value={uploadWorkData.weight.value}
                                        title={'Size'}
                                        disableMargin={true}
                                        setInputValue={val => {
                                            setUploadWorkData(val)
                                        }}
                                        newDropdownIcon={<svg width="16" height="10" viewBox="0 0 16 10" fill="none"
                                                              xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M16 0V1.73624L7.98294 9.77778L0 1.73624V0L7.98294 8.04154L16 0Z"
                                                fill="black"/>
                                        </svg>
                                        }
                                        unFilteredArray={true}
                                        placeholder={'Delivery sizes'}
                                        optionLabel={'weight'}
                                        error={uploadWorkData.weight.error}
                                        newError={uploadWorkData.weight.error}
                                        errorTitle={uploadWorkData.weight.errorMessage}
                                        defaultDropdown
                                        borderDropdown
                                        search
                                        onChange={({target}) => {
                                            setUploadWorkData(prevState => ({
                                                ...prevState,
                                                weight: {
                                                    ...prevState.weight,
                                                    value: target.value,
                                                    error: false,
                                                    errorMessage: null,
                                                }
                                            }))
                                        }}
                                        popup={uploadWorkData.weight.popup}
                                        dropdownPadding={'5px 16px'}
                                        options={uploadWorkData.weight.options}
                        />
                        {loading ?
                            infoDetails(uploadWorkData.materials, 'materials', 'Materials')
                            : null}
                        <TextInput title={'Cost of used materials, €'}
                                   value={uploadWorkData.cost_of_materials.value}
                                   error={uploadWorkData.cost_of_materials.error}
                                   newError={uploadWorkData.cost_of_materials.error}
                                   borderBottomInput
                                   errTitle={uploadWorkData.cost_of_materials.errorMessage}
                                   onChange={({target}) => {
                                       if (regExp.only_number.test(target.value) && target.value < 2147483648) {
                                           if (target.value[0] === '0') {
                                               setUploadWorkData(state => ({
                                                       ...state, cost_of_materials: {
                                                           ...state.cost_of_materials,
                                                           value: '',
                                                           error: false,
                                                           errorMessage: null,
                                                       }
                                                   }
                                               ))
                                           } else {
                                               setUploadWorkData(state => ({
                                                       ...state, cost_of_materials: {
                                                           ...state.cost_of_materials,
                                                           value: target.value,
                                                           error: false,
                                                           errorMessage: null,
                                                       }
                                                   }
                                               ))
                                           }
                                       }
                                   }}/>
                    </div>
                </div>
                <div className={'final_price_wrapper'}>
                    <div className={'final_price_input'}>
                        <TextInput title={'Final price, €'}
                                   value={uploadWorkData.final.value}
                                   error={uploadWorkData.final.error}
                                   newError={uploadWorkData.final.error}
                                   errTitle={uploadWorkData.final.errorMessage}
                                   placeholder={`0-${estimatePrice}`}
                                   borderBottomInput
                                   onChange={({target}) => {
                                       if (regExp.only_number.test(target.value) && target.value <= estimatePrice) {
                                           setUploadWorkData(state => ({
                                                   ...state, final: {
                                                       ...state.final,
                                                       value: target.value,
                                                       error: false,
                                                       errorMessage: null,
                                                   }
                                               }
                                           ))
                                       }
                                   }}/>
                    </div>
                    <div className={'mobile_price'}>
                        <h6>Estimated price</h6>
                        <h5>{loading && estimatePrice}€</h5>
                    </div>
                    <p>This is the maximum price for the artwork based on your CV. You can accept it or set a lower
                        price.</p>
                </div>
            </div>
            <div className={'optional_info_wrapper'}>
                <p className={'description_text'}>
                    Artsted ™ engine utilizes cutting-edge technology to give insight on artists’ careers and artwork
                    price history, providing its users with a transparent and easy-to-navigate experience of art
                    purchase.
                    An AI is trained to recognise and suggest prices for artworks, based on previous entries, sales
                    history and CV performance. To get the best price estimate for your work, please keep your CV
                    up-to-date.
                </p>
                <Link target={'_blank'}
                      className={'update_cv'} to={'/profile/cv'}>Update the CV ></Link>
                <div className={'optional_info_content'}>
                    <h3>Additional Info</h3>
                    <p>Packaging type:</p>
                    <div style={uploadWorkData.optional_info.error ? {margin: '0 0 0 1rem'} : null}
                         className={'optional_packing'}>
                        <RadioButton title={'Tube'}
                                     disabled={!!editMode}
                                     value={uploadWorkData.optional_info.value}
                                     error={uploadWorkData.optional_info.error}
                                     onClick={() => {
                                         if (!editMode) {
                                             setUploadWorkData(state => ({
                                                     ...state, optional_info: {
                                                         ...state.optional_info,
                                                         value: 'Tube',
                                                         error: false,
                                                         errorMessage: null,
                                                     }
                                                 }
                                             ))
                                         }
                                     }}/>
                        <RadioButton title={'Box'}
                                     disabled={!!editMode}
                                     value={uploadWorkData.optional_info.value}
                                     error={uploadWorkData.optional_info.error}
                                     onClick={() => {
                                         if (!editMode) {
                                             setUploadWorkData(state => ({
                                                     ...state, optional_info: {
                                                         ...state.optional_info,
                                                         value: 'Box',
                                                         errorMessage: null,
                                                         error: false
                                                     }
                                                 }
                                             ))
                                         }
                                     }}/>
                    </div>

                    {uploadWorkData.optional_info.error ?
                        handleCustomError('1rem 0 2.5rem', 'Select the packaging type') : null}

                    <div className={'exhibitions_publications_wrapper'}>
                        {uploadProofWrap('Exhibitions participation:', 'exhibition_participation', 'exhibition_participation_proofs')}
                        {uploadProofWrap('Publications:', 'publication', 'publication_proofs')}
                    </div>
                </div>
            </div>

            <div className={'end_upload_work_line'}/>

            <div className={'terms_of_service'}>
                {loading && !editMode ?
                    <CustomCheckbox text={'Agree to the terms of service'}
                                    handleChange={() => {
                                        if (!editMode) {
                                            setUploadWorkData(state => ({
                                                    ...state, agree_terms: {
                                                        ...state.agree_terms,
                                                        value: !state.agree_terms.value,
                                                        error: false,
                                                        errorMessage: null,
                                                    }
                                                }
                                            ))
                                        }
                                    }}
                                    error={uploadWorkData.agree_terms.error}
                                    value={uploadWorkData.agree_terms.value}
                    />
                    : null}

                {uploadWorkData.agree_terms.error ?
                    handleCustomError('1rem 0 2.5rem', 'You must agree to the Terms of service to upload artwork') : null}

            </div>
            <div className={'apply_cancel'}>
                <MainButton type={'button'}
                            className={'button_black'}
                            text={'Cancel'}
                            onClick={() => {
                                setShowUploadWork(false)
                                setEditMode(null)
                                window.scrollTo(0, 0)
                            }}
                />
                <MainButton text={pendingButton
                    ? <Loader color={{
                        '--bgColor': '#fff',
                        '--size': '24px',
                        '--wrapper_size': '12px',
                        '--margin': '-5px'
                    }}/>
                    : editMode ? 'Save changes' : 'Upload artwork'}
                            type={'button'}
                            disabled={pendingButton}
                            className={'button_black'}
                            onClick={(e) => {
                                handleSubmit(e)
                                if (editMode) {
                                    setIsFinished(true)
                                }
                            }}
                            style={{background: '#000', color: '#fff', marginLeft: 'auto'}}
                />

                {pendingButton && <span>Uploading files, please wait</span>}

                {fieldsError
                    ? <div className={'all_fields_error'}>
                        {handleCustomError('1rem 0', 'Fill all the fields')}
                    </div> : null}

                {soldError
                    ? <div className={'all_fields_error'}>
                        {handleCustomError('1rem 0', 'Sold artwork cannot be changed')}
                    </div> : null}
            </div>

            {showPreview ?
                <AuthenticityCertificate uploadWorkData={uploadWorkData}
                                         files={files}
                                         handleCustomError={handleCustomError}
                                         setIsFinished={e => setIsFinished(e)}
                                         setShowPreview={e => setShowPreview(e)}
                                         handleSubmitArtwork={handleSubmit}/>
                : null}
        </form>
    ];
}
